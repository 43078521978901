import React from 'react'
import { StepHeader, Button, InputField, Spinner, Alert, UnexpectedError, Text } from 'ui'
import { errorMessages } from 'resources'
import { useLogin } from './use-login'
import { Wrapper, Form, SendArea, FormItem } from './components'

const LoginStep = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    errors,
    readonly,
    checkingUser,
    alert,
    unexpectedError,
  } = useLogin()

  if (unexpectedError) {
    return <UnexpectedError />
  }

  return (
    <Wrapper>
      <StepHeader
        subTitle='Preencha seus dados para entrar em sua conta'
      />
      <Alert
        message={alert.message}
        visible={alert.show}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>

        <FormItem>
          <InputField
            type='email'
            label='E-mail *'
            name='email'
            readOnly={readonly}
            inputRef={register({ required: errorMessages.required })}
            placeholder='nome@dominio.com'
            error={errors.email}
          />
        </FormItem>
        <FormItem>
          <InputField
            type='password'
            label='Senha *'
            name='password'
            readOnly={readonly}
            inputRef={register({ required: errorMessages.required })}
            placeholder='**********'
            error={errors.password}
          />
        </FormItem>

        <SendArea>
          <Text variant='clear' href='https://app.professorferretto.com.br/recuperar-senha'>Esqueci minha senha</Text>
          <Button readOnly={checkingUser} hasIcon>Próximo passo</Button>
          {checkingUser && <Spinner />}
        </SendArea>
      </Form>
    </Wrapper>
  )
}

export default LoginStep
