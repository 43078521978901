import { useCallback, useState } from 'react'

export const useToggle = initialValue => {
  const [value, toggleValue] = useState(initialValue)

  const fn = useCallback(() => toggleValue(!value), [toggleValue, value])

  return [
    value,
    fn,
    toggleValue,
  ]
}
