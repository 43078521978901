import {
  PAGES,
  extractQueryParams,
  useToggle,
  createContextWithToken,
} from 'resources'
import {
  usePage,
  useFormData,
  useToken,
} from 'contexts'
import { useForm } from 'react-hook-form'
import { useState, useCallback } from 'react'

import { LOGINCHK, ME } from './graphql'
import { useApolloClient, useMutation } from '@apollo/client'
import { isNil, mergeLeft } from 'ramda'
import { useAlert } from 'ui'

import { useNavigate, useLocation } from 'react-router-dom'
import { usePlan } from 'steps/plan/use-plan'

export const useLogin = () => {
  const client = useApolloClient()
  const [unexpectedError, toggleUnexpectedError] = useToggle()
  const [checkingUser, setCheckingUser] = useState(false)
  const { page, setCompletedPages, setPage } = usePage()
  const { formData, setFormData } = useFormData()
  const { refetchPlans } = usePlan()
  const { setLogToken } = useToken()
  const { register, handleSubmit, errors, watch, setValue } = useForm()
  const [alert, showAlert, closeAlert] = useAlert()
  const [loginMutation] = useMutation(LOGINCHK)

  const navigate = useNavigate()
  const { search, pathname } = useLocation()
  const currentParams = new URLSearchParams(search).toString()
  const loginData = useCallback(async data => {
    const input = {
      email: data.email,
      password: data.password,
      origin: 'WEB',
      sendData: data.sendData ? false : !!search,
      params: {
        urlParams: search,
        formType: 'login',
        pathName: pathname,
        host: window.location.host,
      },
    }

    try {
      const { data: result } = await loginMutation({ variables: { input } })

      sessionStorage.setItem('proftkn', JSON.stringify(result.login.token))

      setLogToken(result.login.token)
      return result.login.token
    } catch (e) {
      return e
    }
  }, [loginMutation, pathname, search, setLogToken])

  const afterLogin = useCallback(async () => {
    setCheckingUser(true)
    refetchPlans({
      context: createContextWithToken(
        JSON.parse(sessionStorage.getItem('proftkn'))
      ),
    })
  }, [refetchPlans])

  const queryUserData = useCallback(async (tokenLogin) => {
    const headers = { Authorization: `Bearer ${tokenLogin}` }

    try {
      const { data } = await client.query({
        query: ME,
        context: { headers },
      })
      return data
    } catch (e) {
      return false
    }
  }, [client])

  const onSubmit = useCallback(async data => {
    closeAlert()

    try {
      const result = await loginData(data)

      if (result.message) {
        throw Error(result.message)
      }
      const loginResponse = await afterLogin()

      if (loginResponse === false) {
        throw Error('Ocorreu um erro inesperado, por favor tente novamente em alguns minutos')
      }
      setCompletedPages(mergeLeft({
        [page]: true,
      }))
      const personalData = await queryUserData(result)

      if (!personalData) {
        throw Error('Ocorreu um erro inesperado, por favor tente novamente em alguns minutos')
      }
      setFormData(mergeLeft({
        personalData: personalData.me,
        haveRecentTransaction: personalData.haveRecentTransaction,
      }))

      setCheckingUser(false)
      setPage(PAGES.payment)
      navigate(`/payment?${currentParams}`)
    } catch (e) {
      setCheckingUser(false)
      showAlert(e.message)
    }
  }, [closeAlert, loginData, afterLogin, setCompletedPages, page, queryUserData, setFormData, setPage, navigate, currentParams, showAlert])

  const handleBack = useCallback(e => {
    e.preventDefault()
    setPage(PAGES.plan)
    navigate('/')
  }, [setPage, navigate])

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    watch,
    setValue,
    formData,
    handleBack,
    checkingUser,
    readonly: !isNil(queryParams.token),
    alert,
    closeAlert,
    toggleUnexpectedError,
    unexpectedError,
    loginData,
    queryUserData,
  }
}

const queryParams = extractQueryParams()
