import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

import { media } from 'resources'

export const StepHeader = ({ title, subTitle }) => {
  return (
    <>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </>
  )
}

StepHeader.propTypes = {
  title: t.string,
  subTitle: t.string.isRequired,
}

const Title = styled.h2`
  font-size: clamp(3rem, 5vw, 3.8rem);
  font-weight: 700;
  margin-bottom: 2rem;

  ${media.lessThan('sm')`
    margin-bottom: 1rem;
  `}
`

const SubTitle = styled.h3`
  font-size: clamp(1.2rem, 5vw, 1.4rem);
  font-weight: 600;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.lightText};
  margin-bottom: 3rem;

  ${media.lessThan('sm')`
    margin-bottom: ${({ theme }) => theme.spacing.md};
  `}
`
