import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'
import styledBy from 'styled-by'

import { colors, radius, media } from 'resources'

import icon from './ic-arrow.svg'
import arrow from './ic-arrow-down.svg'

const baseButton = (color1, color2) => css`
  position: relative;
  background: linear-gradient(160deg, ${color1}, ${color2});
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${radius.sm};
    background: linear-gradient(160deg, ${darken(0.05, color1)}, ${darken(0.15, color2)});
    transition: opacity 0.2s linear 0s;
    opacity: 0;
    z-index: -1;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 1;
    }
  }
`

const primaryButton = baseButton(colors.primary, colors.lightBlue)
const successButton = baseButton(colors.success, colors.lightGreen)

const defaultButton = (color1, color2) => css`
  position: relative;
  border: solid 2px ${p => p.theme.colors.border};
  background: ${p => p.theme.colors.background};
  color: ${p => lighten(0.5, p.theme.colors.text)};
  transition: color 200ms;
  &:hover {
    color: ${p => p.theme.colors.text};
  }
`

export const Button = styled.button`
  appearance: none;
  padding: 1.6rem 2.8rem;
  font-size: 1.6rem;
  font-weight: 700;
  border: none;
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.radius.sm};
  transition: background .25s ease;
  text-decoration: none;

  ${media.lessThan('sm')`
    width: 100%;
  `}

  ${styledBy('variant', {
    primary: () => primaryButton,
    success: () => successButton,
    default: () => defaultButton,
  })}

  ${({ hasIcon }) => hasIcon && css`
    padding-right: 5rem;

    &:after {
      content: url(${icon});
      position: absolute;
      right: 2.8rem;
      transition: transform .25s ease;
    }

    &:focus,
    &:hover {
      &:after {
        transform: translateX(.3rem);
      }
    }
  `}

  ${({ hasArrow }) => hasArrow && css`
    padding-right: 5rem;

    &:after {
      content: url(${arrow});
      position: absolute;
      right: 2.8rem;
      transition: transform .25s ease;
    }

    &:focus,
    &:hover {
      &:after {
        transform: translateY(.3rem);
      }
    }
  `}

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

Button.defaultProps = {
  variant: 'primary',
}
