import React from 'react'
import styled from 'styled-components'
import { useWindowWidth } from '@react-hook/window-size/throttled'

import { Logo, SecureBadge, SidebarSteps } from 'ui'
import { media } from 'resources'

export const MobileHeader = () => {
  const width = useWindowWidth()
  return (
    <Wrapper>
      <Headline>
        <Logo />
        {width > 900 && <SidebarSteps inline />}
        <SecureBadge>Ambiente 100% Seguro</SecureBadge>
      </Headline>
      {width <= 900 && <SidebarSteps inline />}
    </Wrapper>
  )
}

const Wrapper = styled.header`
  padding: 2rem 3rem;
  background: ${({ theme }) => `linear-gradient(60deg, ${theme.colors.lightBlue}, ${theme.colors.primary})`};
  z-index: 1;

  ${media.lessThan('sm')`
    padding: 2rem 2rem 1.4rem; 
  `}
`

const Headline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
`
