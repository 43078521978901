import styled from 'styled-components'

import { media } from 'resources'

export const Text = styled.a`
  appearance: none;
  padding-right: 10rem;
  font-size: 1.1rem;
  font-weight: 700;
  border: none;
  position: relative;
  cursor: pointer;
  color: ${p => p.theme.colors.lightBlue};
  border-radius: ${({ theme }) => theme.radius.sm};
  transition: background .25s ease;
  text-decoration: none;

  ${media.lessThan('sm')`
    width: 100%;
  `}
`
