import React, { createContext, useContext } from 'react'
import t from 'prop-types'

const TabContext = createContext()

export default function TabProvider ({ setActiveTab, children }) {
  return (
    <TabContext.Provider
      value={{
        setActiveTab,
      }}
    >
      {children}
    </TabContext.Provider>
  )
}

TabProvider.propTypes = {
  children: t.node,
  setActiveTab: t.func,
}

export function useTab () {
  const context = useContext(TabContext)
  if (!context) throw new Error('useTab must be used within a TabProvider')
  return context
}
