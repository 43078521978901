import React, { forwardRef } from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { media, showCheckedIcon } from 'resources'

import icCheckmark from './ic-checkmark.svg'

export const CheckField = forwardRef(({ children, label, name, checked }, ref) => {
  return (
    <Label>
      <input
        type='checkbox'
        ref={ref}
        name={name}
        defaultChecked={checked}
      />
      <Check />
      <Text>{label}{children}</Text>
    </Label>
  )
})

CheckField.propTypes = {
  children: t.node,
  label: t.string.isRequired,
  name: t.string,
  checked: t.bool,
}

const Check = styled.span`
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.lightPurple};
  cursor: pointer;
  display: flex;
  align-items: center;
`

const Label = styled.label`
  border-top: 2px;
  border-color: black;
  display: flex;
  align-items: center;
  position: relative;
  width: 218px;
  ${media.lessThan('sm')`
    width: 100%;
  `}

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + ${Check} {
      opacity: 1;
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.primary};
      display: flex;
      align-items: center;

      &:before {
        content: url(${icCheckmark});
        position: absolute;
        left: .52rem;
        animation: ${showCheckedIcon} .3s ease forwards;
      }
    }
  }
`

const Text = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  padding-left: 1.4rem;
  cursor: pointer;
  line-height: 1.3;
`
