export const PaymentTypes = {
  CREDIT_CARD: 0,
  BOLETO: 1,
  PIX: 2,
  COUPON: 3,
}

export const PaymentStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
}
