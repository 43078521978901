import React from 'react'
import Loader from 'react-content-loader'
import { colors, radius } from 'resources'

export const ContentLoader = () => (
  <Loader
    speed={2}
    width='100%'
    height='60rem'
    backgroundColor={colors.contentLoadingColor3}
    foregroundColor={colors.contentLoadingColor4}
  >
    <rect x='0' y='0' rx={radius.sm} ry={radius.sm} width='15rem' height='7rem' />
    <rect x='0' y='90' rx={radius.sm} ry={radius.sm} width='100%' height='12rem' />
    <rect x='0' y='230' rx={radius.sm} ry={radius.sm} width='100%' height='12rem' />
    <rect x='0' y='370' rx={radius.sm} ry={radius.sm} width='100%' height='12rem' />
    <rect x='0' y='510' rx={radius.sm} ry={radius.sm} width='150' height='5rem' />
  </Loader>
)
