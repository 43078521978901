export const cpfMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const phoneMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export const cardExpirationMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{2})/, '$1/$2')
    .replace(/(\/\d{2})\d+?$/, '$1')
}

export const cvvMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})/, '$1')
    .replace(/(\d{4})\d+?$/, '$1')
}

export const cardMask = (value, type) => {
  if (type === 'american-express') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{6})(\d)/, '$1 $2')
      .replace(/(-\d{5})\d+?$/, '$1')
      .trim()
  }

  if (type === 'diners-club') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{6})(\d)/, '$1 $2')
      .replace(/(-\d{4})\d+?$/, '$1')
      .trim()
  }

  return value
    .replace(/\D/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
}
