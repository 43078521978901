import { generateMedia } from 'styled-media-query'

const media = generateMedia({
  sm: '580px',
  md: '900px',
  lg: '1100px',
  xl: '1300px',
  xxl: '1600px',
  xxxl: '1900px',
})

export { media }
