import { gql } from '@apollo/client'

export const FINISH_CHECKOUT = gql`
  mutation FinishCheckout($input: FinishCheckoutInput!){
    data: finishCheckout(input: $input) {
      success
      status
      pagarmeId
    }
  }
`
