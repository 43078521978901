import React from 'react'
import t from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { theme, client } from 'resources'
import PageProvider from 'contexts/page'
import FormDataProvider from 'contexts/form-data'
import TokenProvider from 'contexts/token'
import { ApolloProvider } from '@apollo/client'

const providers = [
  {
    component: ApolloProvider,
    props: { client },
  },
  {
    component: ThemeProvider,
    props: { theme },
  },
  {
    component: PageProvider,
  },
  {
    component: FormDataProvider,
  },
  {
    component: TokenProvider,
  },
]

const Providers = ({ children }) => {
  return providers.reduceRight((child, provider) => {
    return <provider.component {...provider.props} children={child} />
  }, children)
}

Providers.propTypes = {
  children: t.node.isRequired,
}

export default Providers
