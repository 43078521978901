export const errorMessages = {
  required: 'Campo obrigatório',
  mismatchedEmail: 'Os e-mails devem ser iguais',
  mismatchedPassoword: 'As senhas devem ser iguais',
  minLengthPassword: 'Mínimo de 6 caracteres',
  selectOption: 'Selecione uma opção',
  minLength: 'Campo inválido',
  invalidCPF: 'CPF inválido',
  invalidCEP: 'CEP inválido',
  invalidDate: 'Data inválida',
  invalidCard: 'Cartão inválido',
}

export const PAGES = {
  plan: 'plan',
  personalData: 'personalData',
  payment: 'payment',
  confirm: 'confirm',
  processingPayment: 'processingPayment',
}
