import { CouponStatus } from './types/plans'

export const extractQueryParams = () => {
  const params = new URLSearchParams(window.location.search)

  return {
    plan: params.get('plano'),
    coupon: params.get('cupom'),
    token: params.get('t'),
  }
}

export const createContextWithToken = token => token
  ? {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  : null

export const formatValue = value => {
  if (value === 0) {
    return {
      raw: 0,
      formatted: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(0),
    }
  }
  return value
    ? {
      raw: value,
      formatted: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value),
    }
    : null
}

export const hasUseRenewDiscount = (plan, hasCoupon) => (
  plan.valueWithRenewalDiscount &&
  (
    !hasCoupon ||
    plan.valueWithRenewalDiscount?.raw < plan.valueWithCoupon?.raw
  )
)

export const getPlanValue = plan => {
  const hasCoupon = CouponStatus.eq(plan.couponStatus, CouponStatus.APPLIED)

  if (hasUseRenewDiscount(plan, hasCoupon)) return plan.valueWithRenewalDiscount

  if (hasCoupon) return plan.valueWithCoupon

  return plan.totalValue
}

export const translatePageName = page => {
  const PAGES = {
    plan: 'plano',
    personalData: 'dados-pessoais',
    payment: 'pagamento',
    confirm: 'confirmacao',
    notFound: 'erro',
  }

  return PAGES[page] || PAGES.notFound
}

export const getWebPlatformRedirectUrl = () => {
  const webPlatformUrl = process.env.REACT_APP_PLATFORM_URL

  if (webPlatformUrl.endsWith('/')) {
    return webPlatformUrl + 'logout'
  }

  return webPlatformUrl + '/logout'
}

export const getFinalValue = (formData) => {
  const { paymentType, selectedPlan } = formData
  let finalValue = selectedPlan.totalValue.formatted

  if (selectedPlan.valueWithRenewalDiscount) {
    finalValue = selectedPlan.valueWithRenewalDiscount.formatted
  }
  if (selectedPlan.valueWithCoupon) {
    finalValue = selectedPlan.valueWithCoupon.formatted
  }
  if (paymentType === 'PIX' && selectedPlan.valueWithPixDiscount) {
    finalValue = selectedPlan.valueWithPixDiscount.formatted
  }
  return finalValue
}
