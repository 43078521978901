import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'

import { Smalllogo } from 'ui'
import { theme, media } from 'resources'

export const TabButton = ({ active, title, handleClick, type, disabled }) => {
  return (
    <Wrapper active={active} disabled={disabled} onClick={handleClick}>
      {/* {disabled && <Smalllogo type={type} color={theme.colors.lightPurple} />} */}
      {disabled ? <Smalllogo type={type} color={theme.colors.lightPurple} />
        : active ? <Smalllogo type={type} color={theme.colors.primary} /> : <Smalllogo type={type} color={theme.colors.lightPurple} />}
      <Title active={active} disabled={disabled}>{title}</Title>
    </Wrapper>
  )
}

TabButton.propTypes = {
  active: t.bool,
  title: t.string.isRequired,
  handleClick: t.func.isRequired,
  type: t.number,
  disabled: t.bool,
}

const Wrapper = styled.button`
  outline: none;
  appearance: none;
  border: none;
  position: relative;
  padding: 2rem 2.7rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.lightPurple};
  border-radius: ${({ theme }) => theme.radius.sm};
  height: 78px;

  ${({ active, theme }) => active && css`
    border: 2px solid ${theme.colors.primary};
    background-color: ${theme.colors.third};
  `}

  ${({ disabled, theme }) => disabled && css`
    border: 1px solid ${theme.colors.lightPurple};
    background-color: ${theme.colors.background};
  `}

  ${media.lessThan('xxl')`
    padding: 1.6rem 2rem;
    border-width: 1px;
    height: 54px;
  `}
`

const Title = styled.p`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.lightPurple};
  margin-left: 2rem;

  ${({ active, theme }) => active && css`
    color: ${theme.colors.primary};
  `}

  ${({ disabled, theme }) => disabled && css`
    color: ${theme.colors.lightPurple};
  `}

  ${media.lessThan('xxl')`
    font-size: 1.4rem;
    margin-left: 1.4rem;
  `}
`
