import React, { useCallback, useState, useEffect, createContext, useContext, useRef } from 'react'
import t from 'prop-types'
import { useFormData, useErrorHandler } from 'contexts'
import { extractQueryParams, formatValue, useToggle, createContextWithToken } from 'resources'
import { useApolloClient, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { CouponStatus } from 'resources/types/plans'
import { conditionalMap } from 'resources/composable'
import { useDebouncedCallback } from 'use-debounce'
import {
  mergeRight,
  propEq,
  identity,
  map,
  evolve,
  pipe,
  path,
  pick,
  always,
} from 'ramda'

import { PLANS, VALIDATE_COUPON } from './graphql'
import { useAlert } from 'ui'

export const PlanContext = createContext({})

export const PlanContextProvider = ({ children }) => {
  const client = useApolloClient()
  const [unexpectedError] = useToggle(false)
  const [status, setStatus] = useState(Status.LOADING)
  const [validatingCoupon, setValidatingCoupon] = useState(false)
  const { setFormData, formData } = useFormData()
  const [plans, setPlans] = useState([])
  const [alert, showAlert, closeAlert] = useAlert()
  const [isBlackFriday, setIsBlackFriday] = useState(false)
  const { setIsError } = useErrorHandler()
  const activeCouponsMap = useRef(new Map())
  const { token, coupon, plan } = extractQueryParams()
  const [initialPlansValues, setInitialPlansValues] = useState([])

  const { refetch: refetchPlans } = useQuery(PLANS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => onPlansQuerySuccess(data),
    onError: () => {
      setIsError({ Error: true, code: '129' })
    },
    context: createContextWithToken(
      token || JSON.parse(sessionStorage.getItem('proftkn'))
    ),
  })

  const validateCoupon = useCallback(
    async (planId, couponName, callback, renewalValue) => {
      const variables = {
        input: {
          planId,
          coupon: couponName.toUpperCase(),
        },
      }

      try {
        const { data } = await client.query({
          variables,
          query: VALIDATE_COUPON,
          context: createContextWithToken(
            token || JSON.parse(sessionStorage.getItem('proftkn'))
          ),
        })
        const plan = path(['validateCoupon', 'plan'], data)

        const props = {
          couponStatus: plan ? CouponStatus.APPLIED : CouponStatus.INVALID,
          couponValues: plan ? createCouponValues(plan) : {},
          couponName,
        }

        if (plan?.valueWithCoupon > renewalValue?.raw) {
          props.couponStatus = CouponStatus.LESS_THAN_RENEW_DISCOUNT
          props.couponValues = {}
        }

        activeCouponsMap.current.set(planId, props)

        callback && callback(props)

        if (alert) {
          closeAlert()
        }

        return props
      } catch (e) {
        const props = {
          couponName,
        }
        activeCouponsMap.current.set(planId, props)
        showAlert(e.message)
        setPlans(initialPlansValues)
        setValidatingCoupon(false)
        Sentry.captureException(e)
      }
    },
    [alert, client, closeAlert, initialPlansValues, showAlert, token]
  )

  const { callback: debouncedCouponValidation, cancel: cancelValidation } =
    useDebouncedCallback(validateCoupon, 1000)

  const handlePlanSelect = useCallback(
    (planId) => {
      setFormData((previousValue) => ({
        ...previousValue,
        selectedPlan: plans.find((plan) => plan.id === planId),
      }))
    },
    [plans, setFormData]
  )

  const createPlanWithCoupon = useCallback(
    (plan, callback) =>
      ({ couponStatus, couponValues, couponName }) => {
        // eslint-disable-next-line standard/no-callback-literal
        return callback({ ...plan, couponStatus, couponName, ...couponValues })
      },
    []
  )

  const onPlansQuerySuccess = useCallback(
    async (data) => {
      const plans = formatPlanValues(data.plans)
      setInitialPlansValues(plans)
      let plansWithCoupons = [...plans]

      for (const planId of activeCouponsMap.current.keys()) {
        if (activeCouponsMap.current.get(planId)) {
          setValidatingCoupon(true)
          await validateCoupon(
            planId,
            activeCouponsMap.current.get(planId).couponName,
            createPlanWithCoupon(
              plansWithCoupons.find((p) => p.id === planId),
              // eslint-disable-next-line no-loop-func
              (planWithCoupon) => {
                plansWithCoupons = plansWithCoupons.map((p) =>
                  p.id === planWithCoupon.id ? planWithCoupon : p
                )
              }
            ),
            plansWithCoupons.find((p) => p.id === planId)
              .valueWithRenewalDiscount
          )
        }
      }

      setValidatingCoupon(false)
      setPlans(plansWithCoupons)
      setFormData((prev) => ({
        ...prev,
        selectedPlan: plansWithCoupons.find(
          (pc) => pc.id === prev.selectedPlan?.id
        ),
      }))
      setStatus(Status.DATA)
    },
    [validateCoupon, createPlanWithCoupon, setFormData]
  )

  /**
   *
   * @param {string} planId
   * @param {{ raw: string }} renewalValue
   * @param {string} value - O nome do cupom
   */
  const handleCouponChange = async (planId, renewalValue, value) => {
    if (!validatingCoupon) setValidatingCoupon(true)

    const isEmpty = value.length === 0
    const couponStatus = (current) =>
      isEmpty ? CouponStatus.NOT_SELECTED : current

    setPlans(
      conditionalMap(
        idEq(planId),
        evolve({
          couponName: always(value),
          couponStatus,
        }),
        identity
      )
    )

    if (isEmpty) {
      setFormData((prev) => ({
        ...prev,
        selectedPlan: {
          ...prev.selectedPlan,
          couponStatus: 2,
          couponName: null,
          valueWithCoupon: null,
          valueOfInstallmentWithCoupon: null,
        },
      }))
      setValidatingCoupon(false)
      cancelValidation()
      setPlans(initialPlansValues)
    } else {
      debouncedCouponValidation(
        planId,
        value,
        createPlanWithCoupon(
          plans.find(p => p.id === planId),
          (planWithCoupon) => {
            setPlans(prev => [...prev.map((p) => (p.id === planId) ? planWithCoupon : p)])
            setValidatingCoupon(false)
          }
        ),
        renewalValue
      )
    }
  }

  useEffect(function init () {
    if (BlackFriday()) {
      setIsBlackFriday(true)
    }
  }, [])

  useEffect(
    function handleCouponAsURLParameter () {
      (async () => {
        if (
          activeCouponsMap.current.size === 0 &&
          coupon &&
          plan &&
          plans.length > 0
        ) {
          await validateCoupon(
            plan,
            coupon,
            createPlanWithCoupon(
              plans.find((p) => p.id === plan),
              (planWithCoupon) => {
                setPlans((prev) => [
                  ...prev.map((p) => (p.id === plan ? planWithCoupon : p)),
                ])
                setValidatingCoupon(false)
              }
            )
          )
        }
      })()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      validateCoupon,
      coupon,
      plan,
      setFormData,
      activeCouponsMap.current,
      plans,
    ]
  )

  useEffect(
    function updateSelectedPlanAfterUpdatePlans () {
      if (plans) {
        setFormData((prev) => ({
          ...prev,
          selectedPlan: prev.selectedPlan?.id
            ? plans.find((p) => prev.selectedPlan?.id === p.id)
            : plans.find((p) => plan === p.id),
        }))
      }
    },
    [plan, plans, setFormData]
  )

  return (
    <PlanContext.Provider
      value={{
        refetchPlans,
        handlePlanSelect,
        handleCouponChange,
        plans,
        status,
        validatingCoupon,
        hasSelectedPlan: formData?.selectedPlan?.id,
        unexpectedError,
        closeAlert,
        alert,
        isBlackFriday,
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}

PlanContextProvider.propTypes = {
  children: t.node.isRequired,
}

export const usePlan = () => {
  const context = useContext(PlanContext)

  if (!context) { throw new Error('You should only use the usePlan hook inside of the PlanContextProvider') }

  return context
}

export const Status = {
  LOADING: 0,
  DATA: 1,
}

const addAdditionalProps = plan => mergeRight(plan, {
  couponStatus: CouponStatus.NOT_SELECTED,
})

const formatPlanValues = map(pipe(
  addAdditionalProps,
  evolve({
    totalValue: formatValue,
    valueOfInstallment: formatValue,
    valueWithRenewalDiscount: formatValue,
    valueOfInstallmentWithRenewalDiscount: formatValue,
    valueWithPixDiscount: formatValue,
  })
))

const createCouponValues = pipe(
  pick(['valueOfInstallmentWithCoupon', 'valueWithCoupon', 'valueWithPixDiscount']),
  evolve({
    valueOfInstallmentWithCoupon: formatValue,
    valueWithCoupon: formatValue,
    valueWithPixDiscount: formatValue,
  })
)
const idEq = propEq('id')

const BlackFriday = () => {
  const today = new Date()
  const init = new Date('2022-11-07T03:00:00.000Z') // + 3 horas
  const end = new Date('2022-12-06T03:00:00.000Z') // + 3 horas

  return today >= init && today <= end
}
