import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'

import { media } from 'resources'

export const StepPix = ({ active }) => {
  return (
    <Content>
      <Step>Passo a passo</Step>
      <Container>
        <Wrapper active={active === 1}>
          <Number><Text>1</Text></Number>
          <Title>Preencha seus dados no formulário e clique em Gerar Pix</Title>
        </Wrapper>
        <Wrapper active={active === 2}>
          <Number><Text>2</Text></Number>
          <Title>Efetue o pagamento pelo app do seu banco ou carteira digital através da leitura do QR Code ou Pix Copia e Cola</Title>
        </Wrapper>
        <Wrapper active={active === 3}>
          <Number><Text>3</Text></Number>
          <Title>Seu acesso será liberado assim que o pagamento for confirmado - vamos te avisar por email</Title>
        </Wrapper>
      </Container>
    </Content>
  )
}

StepPix.propTypes = {
  active: t.number,
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Content = styled.div`
  flex-direction: column;

  ${media.greaterThan('md')`
      margin-left: 12rem;
  `}
 
`
const Wrapper = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  outline: none;
  appearance: none;
  border: none;
  padding: 2rem 2.7rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.lightPurple};
  border-radius: ${({ theme }) => theme.radius.sm};
  margin-right: 5rem;
  width: calc(100%/3);
  height: 200px;

  ${({ active, theme }) => active && css`
    border: 2px solid ${theme.colors.primary};
  `}

  ${media.lessThan('xxl')`
    padding: 1.6rem 2rem;
    border-width: 1px;
  `}
`
const Step = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: black;
  margin-bottom: 1rem;
`
const Title = styled.p`
  font-size: 2rem;
  color: black;
  margin-left: 2rem;
  margin-bottom: 1rem;

  ${media.lessThan('xxl')`
    font-size: 1.4rem;
    margin-left: 1.4rem;
  `}
`
const Text = styled.p`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-size: 2rem;
  color: black;
  background-color: ${({ theme }) => theme.colors.third};
  margin: 1rem;
`
