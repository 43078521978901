import styled from 'styled-components'

import { media } from 'resources'

export const Container = styled.main`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  position: relative;

  ${media.greaterThan('md')`
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    grid-gap: 1rem;
  `}

  ${media.greaterThan('xxxl')`
    max-width: 1300px;
    margin: 0 auto;
  `}
`
