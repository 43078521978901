import React from 'react'
import styled from 'styled-components'
import { StepHeader, EssayCreditCard } from 'ui'
import { UseEssayCredits } from './use-essay-credits'
import { ContentLoader } from './content-loader'

const EssayCreditStep = () => {
  const { plans, handlePlanSelect, loading } = UseEssayCredits()
  const now = new Date()

  return (
    <Wrapper>
      {loading ? <ContentLoader /> : (
        <>
          {new Date(plans[0]?.expiresIn).getTime() > now.getTime() ? (
            <>
              <StepHeader
                subTitle='Escolha seu plano:'
              />
              <Plans>
                {plans.map((plan) => (
                  <EssayCreditCard
                    key={plan.id}
                    id={plan.id}
                    active={plan.selected}
                    onSelect={handlePlanSelect}
                    title={`Quantidade de créditos: ${plan.monthlyCredits * plan.accessTimeInMonths}`}
                    numberOfInstallments={plan.numberOfInstallments}
                    valueOfInstallment={plan.valueOfInstallment}
                    value={plan.totalValue}
                  />
                ))}
              </Plans>
              <Details>
                <ul>
                  <li>O prazo para o recebimento da correção da redação é de até 7 dias</li>
                  <li>Você só poderá enviar uma nova redação após receber a correção da redação enviada anteriormente</li>
                  <li>Você só poderá enviar no máximo 4 redações por mês</li>
                </ul>
              </Details>
            </>
          ) : (
            <>
              <StepHeader
                title='Olá!'
              />
              <Infomation>
                <p>Parece que você não pode comprar mais créditos de redação.</p>
                <p>
                  Em caso de dúvidas, você pode entrar em contato com o time de suporte através da <a rel='noopener noreferrer' target='_blank' href='https://app.professorferretto.com.br/central-de-ajuda'>Central de Ajuda</a>.
                </p>
              </Infomation>
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const Plans = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`
const Details = styled.div`
  width: 100%;
  margin-bottom: 24px;
  ul {
    padding: 0;
    list-style: none;
    li {
      font-size: 1.4rem;
      font-weight: bold;
      & + li {
        margin-top: 8px;
      }
    }
  }
`
const Infomation = styled.div`
  width: 100%;
  margin: 16px 0;
  p {
    font-size: 2rem;
  }
  a {
    text-decoration: none;
    color:#000;
    transition: color 0.2s;
    &:hover {
      color: #1479FF
    }
  }
`

export default EssayCreditStep
