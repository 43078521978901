import React from 'react'
import t from 'prop-types'

import { FieldControl, FieldLabel, FieldError, Field } from 'ui'

export const InputField = ({
  label,
  type = 'text',
  name,
  id,
  placeholder,
  error,
  inputRef,
  disabled,
  cardFlag,
  ...rest
}) => {
  return (
    <FieldControl>
      <FieldLabel htmlFor={id}>{label}</FieldLabel>
      <Field
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        ref={inputRef}
        error={error}
        disabled={disabled}
        cardFlag={cardFlag}
        {...rest}
      />
      {error && <FieldError>{error?.message}</FieldError>}
    </FieldControl>
  )
}

InputField.propTypes = {
  type: t.string,
  placeholder: t.string,
  cardFlag: t.string,
  error: t.object,
  disabled: t.bool,
  name: t.string.isRequired,
  id: t.string,
  inputRef: t.func.isRequired,
  label: t.string.isRequired,
}
