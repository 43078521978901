import React from 'react'
import { media } from 'resources'
import styled, { css } from 'styled-components'

import { StepHeader, Button } from 'ui'

import { Result, Header, Information, Details, DetailsItem } from '../../styles/shared'
import { useConfirmBoleto } from './use-confirm-boleto'

export const ConfirmBoleto = () => {
  const {
    totalValue,
    planName,
    boletoUrl,
    boletoCode,
    copy,
    copied,
  } = useConfirmBoleto()

  return (
    <Wrapper>
      <StepHeader
        title='Você está quase lá...'
        subTitle='Confira as informações abaixo e efetue o pagamento'
      />
      <Result>
        <Header>
          <Information>
            O seu acesso ao conteúdo pago da plataforma será disponibilizado <Highlight>após o pagamento do Boleto ser processado.</Highlight> <Highlight alert>Isso pode demorar até 3 dias úteis</Highlight>. Infelizmente, não é possível liberar o acesso antes do processamento bancário.
          </Information>
        </Header>
        <Details>
          <DetailsItem>
            <span>Plano escolhido</span>
            <span>{planName}</span>
          </DetailsItem>
          <DetailsItem>
            <span>Valor do plano</span>
            <span>{totalValue.formatted} à vista no boleto</span>
          </DetailsItem>
        </Details>
      </Result>
      <SendArea>
        <Button href={boletoUrl} target='_blank' as='a' variant='success' hasArrow>Baixar boleto</Button>
        <Button
          onClick={() => copy(boletoCode)}
        >
          {!copied ? 'Copiar código do boleto' : 'Copiado!'}
        </Button>
      </SendArea>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`

const Highlight = styled.span`
  font-weight: 600;

  ${({ alert, theme }) => alert && css`
    font-weight: 700;
    color: ${theme.colors.error};
  `}
`

const SendArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('sm')`
    flex-direction: column-reverse;
    >*:nth-child(2) {
      margin-bottom: 1rem;
    }
  `}
`
