import React, { createContext, useState, useContext } from 'react'
import t from 'prop-types'
import { PAGES } from 'resources'

const PageContext = createContext()

export default function PageProvider ({ children }) {
  const [page, setPage] = useState(PAGES.personalData)
  const [completedPages, setCompletedPages] = useState({
    [PAGES.plan]: false,
    [PAGES.personalData]: false,
    [PAGES.payment]: false,
    [PAGES.confirm]: false,
  })

  return (
    <PageContext.Provider
      value={{
        page,
        setPage,
        completedPages,
        setCompletedPages,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

PageProvider.propTypes = {
  children: t.node,
}

export function usePage () {
  const context = useContext(PageContext)
  if (!context) throw new Error('usePage must be used within a PageProvider')
  return context
}
