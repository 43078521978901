import React, { useState } from 'react'
import { Tabs } from 'antd'
import PersonalDataStep from 'steps/personal-data/personal-data'
import './tabs.css'
import styled from 'styled-components'
import { media } from 'resources'
import LoginStep from 'steps/login/login'
import TabProvider from '../../contexts/tab'

const TabStep = () => {
  const [activeKey, setActiveKey] = useState('1')

  const onChange = (key) => {
    setActiveKey(key)
  }

  const items = [
    {
      key: '1',
      label: 'Criar conta',
      children: <PersonalDataStep />,
    },
    {
      key: '2',
      label: 'Fazer login',
      children: <LoginStep />,
    },
  ]

  return (
    <TabProvider setActiveTab={(key) => setActiveKey(key)}>
      <Div>
        <Tabs
          activeKey={activeKey}
          items={items}
          onChange={onChange}
          size='large'
          tabBarStyle={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        />
      </Div>
    </TabProvider>
  )
}

export default TabStep

const Div = styled.div`
  ${media.lessThan('sm')`
        width: 100%;
    `}
`
