import { useCallback, useState } from 'react'
import { useFormData } from 'contexts'
import { useQuery } from '@apollo/client'
import { extractQueryParams, createContextWithToken, formatValue } from 'resources'
import {
  mergeRight,
  evolve,
  pipe,
  prop,
  map,
} from 'ramda'

import { PLANS } from './graphql'

export const UseEssayCredits = () => {
  const [plans, setPlans] = useState([])
  const { setFormData } = useFormData()

  const handleCompletedQuery = useCallback(result => {
    if (result.essayPlansCreditsByAccessTimeInMonths) {
      const plansFormatted = formatPlanValues(result.essayPlansCreditsByAccessTimeInMonths)
      setPlans(plansFormatted)
    }
  }, [setPlans])

  const { loading } = useQuery(PLANS, {
    fetchPolicy: 'no-cache',
    context: createContextWithToken(queryParams.token),
    onCompleted: handleCompletedQuery,
  })

  const handlePlanSelect = useCallback(
    (planId) => {
      const updatedPlans = [...plans]

      updatedPlans.forEach((plan) => {
        plan.selected = plan.id === planId
      })

      setPlans(updatedPlans)

      setFormData((previousValue) => ({
        ...previousValue,
        selectedPlan: updatedPlans.find((plan) => plan.id === planId),
      }))
    },
    [plans, setPlans, setFormData]
  )

  return {
    handlePlanSelect,
    loading,
    plans,
    hasSelectedPlan: plans.some(prop('selected')),
  }
}

const addAdditionalProps = plan => mergeRight(plan, {
  selected: false,
})

const formatPlanValues = map(pipe(
  addAdditionalProps,
  evolve({
    totalValue: formatValue,
    valueOfInstallment: formatValue,
  })
))

const queryParams = extractQueryParams()
