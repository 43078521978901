import React from 'react'
import t from 'prop-types'
import styled, { keyframes, css } from 'styled-components'

const Spinner = ({ size = '2rem' }) => <SpinnerLoading size={size} />

Spinner.propTypes = {
  size: t.string,
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinnerLoading = styled.div`
  ${({ size }) => css`
    width: ${size};
    height: ${size};
    font-size: ${size};
  `}
  border: solid .15em ${({ theme }) => theme.colors.primary};
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  animation: ${rotate} 1s linear infinite;
`

export { Spinner }
