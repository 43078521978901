import React, { createContext, useContext, useState, useEffect } from 'react'
import t from 'prop-types'

const FormDataContext = createContext()

export default function FormDataProvider ({ children }) {
  const [formData, setFormData] = useState({})
  const [typeOfProduct, setTypeOfProduct] = useState('PLANS')

  useEffect(() => {
    const urlToken = window.location.search.includes('t')
    const urlEssayPlan = window.location.pathname === '/creditos-redacao/'
    if (urlToken && urlEssayPlan) {
      setTypeOfProduct('ESSAY_CREDITS')
    }
  }, [])

  return (
    <FormDataContext.Provider
      value={{
        formData,
        setFormData,
        typeOfProduct,
      }}
    >
      {children}
    </FormDataContext.Provider>
  )
}

FormDataProvider.propTypes = {
  children: t.node,
}

export function useFormData () {
  const context = useContext(FormDataContext)
  if (!context) throw new Error('useFormData must be used within a FormDataContext')
  return context
}
