import styled from 'styled-components'

import { media } from 'resources'

import lockIcon from './ic-lock.svg'

export const SecureBadge = styled.p`
  position: relative;
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 2.5rem;
  color: ${({ theme }) => theme.colors.background};
  opacity: .8;
  border: 1px solid ${({ theme }) => theme.colors.background};
  border-radius: 1rem;
  padding: 1.2rem 1.3rem 1.2rem 2rem;
  border-left: 0;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 16px;
    background: url(${lockIcon}) center no-repeat;
    background-size: auto 100%;
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -9px;
  }

  ${media.lessThan('sm')`
    font-size: 1.1rem;
    padding: 1rem 1.3rem 1rem 2rem;
  `}
`
