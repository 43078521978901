import { useFormData } from 'contexts'
import { isEmpty, isNil } from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'

import { rgba } from 'polished'

import CHECK_SUCCESS from './check-success.svg'
import { CouponStatus } from 'resources/types/plans'
import { hasUseRenewDiscount, getFinalValue } from 'resources'

export const SelectedPlanInfos = () => {
  const { formData, typeOfProduct } = useFormData()
  const { selectedPlan } = formData

  if (!isEmpty(selectedPlan) && !isNil(selectedPlan)) {
    const hasDiscount = checkIfHasDiscount(selectedPlan)
    const discountMessage = getDiscountMessage(selectedPlan)

    return (
      <Wrapper>
        <Title>Resumo da sua assinatura</Title>
        {typeOfProduct === 'ESSAY_CREDITS' ? (
          <Description>
            Você está comprando <span>{selectedPlan.monthlyCredits * selectedPlan.accessTimeInMonths}</span> créditos para correção de redação por <span><Price invalid={hasDiscount}>{selectedPlan.totalValue.formatted}</Price></span>
          </Description>
        ) : (
          <Description>
            Você está comprando o plano <span>{selectedPlan.name}</span>  {hasDiscount ? <span>de <Price invalid={hasDiscount}>{selectedPlan.totalValue.formatted}</Price> por {getFinalValue(formData)}</span> : <span>por <Price>{getFinalValue(formData)}</Price></span>}
          </Description>
        )}
        {hasDiscount && (
          <DiscountWrapper>
            <DiscountIcon><img alt='' src={CHECK_SUCCESS} /></DiscountIcon>
            <Discount>{discountMessage}</Discount>
          </DiscountWrapper>
        )}
      </Wrapper>
    )
  }

  return null
}

const getDiscountMessage = plan => {
  const hasCoupon = plan.couponStatus === CouponStatus.APPLIED

  if (hasUseRenewDiscount(plan, hasCoupon)) return 'Parabéns! Você possui desconto de renovação.'

  if (hasCoupon) return 'Cupom de desconto aplicado'

  return ''
}

const checkIfHasDiscount = plan => plan.couponStatus === CouponStatus.APPLIED || !isNil(plan.valueWithRenewalDiscount)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => rgba(theme.colors.primary, 0.04)};
  padding: 1.6rem 2rem;
  border-radius: 10px;
`

const Title = styled.h4`
  font-size: 1.6rem;
  letter-spacing: -0.03em;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.primary};
`

const Description = styled.p`
  font-weight: normal;
  font-size: 1.4rem;
  letter-spacing: -0.05em;
  margin: 0;
  margin-bottom: 0.35rem;
  color: #000;
  line-height: 1.4;

  span {
    font-weight: 600;
  }
`

const DiscountWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.65rem;
`

const Discount = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: -0.05em;
  margin: 0;
  color: ${({ theme }) => theme.colors.success};
`

const DiscountIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.success};
  margin-right: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Price = styled.span`
  ${props => props.invalid && css`
    opacity: .4;
    text-decoration: line-through;
  `}
`
