import { gql } from '@apollo/client'

export const ME = gql`
  {
    me {
      id
      name
      email
      surname
      cpf
      phone
      state
      city
      neighborhood
      street
      street_number
      zipcode
      complementary
    }
      haveRecentTransaction
  }
`

export const EXISTS_QUERY = gql`
  query ExistsUser($email: String!) {
    plans: userExists(email: $email){
        id
        name
        order
        bestOption
        icon
        accessTimeInMonths
        totalValue
        numberOfInstallments
        valueOfInstallment
        renewalDiscount
        valueWithRenewalDiscount
        valueWithCoupon
        valueOfInstallmentWithCoupon
    }
  }
`

export const SAVE_LEAD = gql`
  mutation SaveLead($input: UserRegisterInput!) {
    saveLead(input: $input)
  }
`

export const CREATE_USER = gql`
mutation createFreeUser($input: FreeUserRegisterInput!) {
  createFreeUser(input: $input)
}
`
