import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba, lighten, darken } from 'polished'
import { Checkmark, Spinner } from 'ui'
import { media } from 'resources'
import { CouponStatus } from 'resources/types/plans'
import imageBlackDays from './selo.png'

import CHECK_SUCCESS from './check-success.svg'

const {
  APPLIED,
  INVALID,
  LESS_THAN_RENEW_DISCOUNT,
} = CouponStatus

export const PlanCard = ({
  id,
  active,
  title,
  duration,
  value,
  recommended,
  installmentValue,
  numberOfInstallments,
  onSelect,
  couponStatus,
  applyingCoupon,
  onCouponChange,
  couponValue,
  renewalValue,
  renewalInstallmentValue,
  valueWithCoupon,
  installmentValueWithCoupon,
  hasEssay,
  isBlackFriday,
  valueWithPixDiscount,
}) => {
  const renderPlanTitle = (accessTimeInMonths) => {
    if (accessTimeInMonths > 12) {
      return (<PlanTitle>12 MESES DE ACESSO + {accessTimeInMonths - 12} {accessTimeInMonths - 12 === 1 ? 'MÊS' : 'MESES'} DE BÔNUS</PlanTitle>)
    }
    if (accessTimeInMonths === 2) {
      return (<PlanTitle>1 MÊS DE ACESSO + 1 MÊS DE BÔNUS</PlanTitle>)
    }
  }

  return (
    <Wrapper active={active} onClick={() => onSelect(id)}>
      {recommended && <Ribbon>Recomendado</Ribbon>}

      <Checkmark active={active} />
      <Content>
        <Title>{title}</Title>
        {renderPlanTitle(duration)}
        {isBlackFriday && duration === 14 ? (
          <BlackDaysImage>
            <img src={imageBlackDays} alt='black days' />
          </BlackDaysImage>
        ) : null}
        <PlanValue invalid={CouponStatus.eq(couponStatus, APPLIED) || renewalValue}>
          {numberOfInstallments > 1 ? `${numberOfInstallments}x de ${installmentValue.formatted} ou ` : ''}{value?.formatted}
        </PlanValue>

        {!applyingCoupon && CouponStatus.eq(couponStatus, APPLIED) && (
          <NewPlanValue>
            {numberOfInstallments > 1 ? `${numberOfInstallments}x de ${installmentValueWithCoupon.formatted} ou ` : ''}{valueWithCoupon.formatted}
          </NewPlanValue>
        )}

        {renewalValue && !CouponStatus.eq(couponStatus, APPLIED) && (
          <RenewalWrapper>
            <RenewalValue>
              {numberOfInstallments > 1 ? `${numberOfInstallments}x de ${renewalInstallmentValue.formatted} ou ` : ''}{renewalValue.formatted}
            </RenewalValue>
            <RenewalMessage>
              <RenewalLabel>Renovação com desconto</RenewalLabel>
              <RenewalIcon />
            </RenewalMessage>
          </RenewalWrapper>
        )}
        {valueWithPixDiscount && (
          <RenewalWrapper>
            <PixValue>
              ou {valueWithPixDiscount.formatted} no PIX
            </PixValue>
          </RenewalWrapper>
        )}

        <CouponWrapper>
          <CouponField
            placeholder='Cupom'
            name='coupon'
            onChange={(event) => onCouponChange(id, renewalValue, event.currentTarget.value)}
            value={couponValue}
            spellCheck='false'
          />

          {applyingCoupon && (
            <CouponSpinner>
              <Spinner size='1.6rem' />
            </CouponSpinner>
          )}

          {!applyingCoupon && CouponStatus.eq(couponStatus, APPLIED) && <IconSuccess absolute />}
        </CouponWrapper>
        {!applyingCoupon && CouponStatus.eq(couponStatus, INVALID) && (
          <InvalidMessage>Cupom inválido</InvalidMessage>
        )}

        {hasEssay ? <EssayMessage>Todas as disciplinas + correção de redação (4 créditos mensais)</EssayMessage> : <EssayMessage>Todas as disciplinas - sem correção de redação</EssayMessage>}
        {!applyingCoupon && CouponStatus.eq(couponStatus, LESS_THAN_RENEW_DISCOUNT) && (
          <WarningMessage>Você possui desconto de renovação com desconto maior que o cupom aplicado. O cupom aplicado foi desconsiderado.</WarningMessage>
        )}

      </Content>
    </Wrapper>
  )
}

PlanCard.propTypes = {
  id: t.string.isRequired,
  active: t.bool,
  title: t.string.isRequired,
  duration: t.number.isRequired,
  value: t.object,
  recommended: t.bool,
  onSelect: t.func.isRequired,
  installmentValue: t.object,
  numberOfInstallments: t.number,
  couponStatus: t.number,
  applyingCoupon: t.bool,
  onCouponChange: t.func,
  couponValue: t.object,
  renewalValue: t.object,
  renewalInstallmentValue: t.object,
  valueWithCoupon: t.object,
  installmentValueWithCoupon: t.object,
  hasEssay: t.bool,
  isBlackFriday: t.bool,
  valueWithPixDiscount: t.object,
}

const PlanTitle = styled.h6`
  color: ${({ theme }) => theme.colors.primary};
  padding: 4px;
  font-weight: 900;
  font-size: 1rem;
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.lightPurple};
  border-radius: ${({ theme }) => theme.radius.lg};
  cursor: pointer;

  ${({ active, theme }) => active && css`
    border-color: ${theme.colors.primary};
    background-color: ${theme.colors.third};
    box-shadow: 0 0 2rem 0 ${rgba(theme.colors.primary, 0.1)};
  `}

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }

  ${media.lessThan('xxl')`
    padding: 1.6rem 2rem;
  `}

  ${media.greaterThan('md')`
    max-width: 54rem;
  `}

  ${media.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;
  `}
`

const Ribbon = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  padding: .7rem 0;
  width: 20rem;
  text-align: center;
  position: absolute;
  top: 3rem;
  right: -6rem;
  transform: rotate(45deg);
  background-color: ${({ theme }) => theme.colors.warning};
  color: ${({ theme }) => theme.colors.secondary};

  ${media.lessThan('xxl')`
    font-size: .8rem;
  `}
`

const Content = styled.div`
  width: 100%;
  padding-left: 1.5rem;

  ${media.lessThan('sm')`
    margin-top: ${({ theme }) => theme.spacing.sm};
  `}
`

const Title = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`

// const Duration = styled.p`
//   font-size: 1.4rem;
//   font-weight: 600;
//   margin: .5rem 0;
//   color: ${({ theme }) => theme.colors.secondary};
// `

const PlanValue = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.lightText};

  ${({ invalid }) => invalid && css`
    text-decoration: line-through;
  `}
`

const CouponField = styled.input.attrs({
  type: 'text',
})`
  border: 1px solid ${({ error, theme }) => error ? theme.colors.error : lighten(0.4, theme.colors.primary)};
  border-radius: ${({ theme }) => theme.radius.sm};
  height: 3rem;
  padding: 0 1rem;
  font-size: 1.2rem;
  margin-right: 1rem;
  outline: none;
  flex-shrink: 1;
  line-height: 1;
  background: ${({ theme }) => theme.colors.background};
  text-transform: uppercase;
  &:focus {
    border-color: ${({ theme }) => lighten(0.2, theme.colors.primary)};
  }
`

const CouponWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 1rem;
  padding-right: 1.5rem;
`

const CouponSpinner = styled.span`
  position: absolute;
  right: 0;
  top: .7rem;
`

const IconSuccess = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  ${p => p.absolute && css`
    position: absolute;
    right: 0;
    top: .6rem;
  `}
  border-radius: 50%;
  background: url(${CHECK_SUCCESS}) no-repeat ${({ theme }) => theme.colors.success};
  background-position: 5px 5px;
`

const InvalidMessage = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => darken(0.08, theme.colors.error)};
  font-weight: bold;
  padding-top: .6rem;
`

const WarningMessage = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.orange};
  font-weight: bold;
  padding-top: .6rem;
`

const EssayMessage = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  padding-top: .6rem;
`

const NewPlanValue = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => darken(0.08, theme.colors.success)};
  font-weight: bold;
  padding-top: .6rem;
  `

const RenewalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const RenewalLabel = styled.p`
  font-size: 1.10rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  margin-right: 1rem;
  text-transform: uppercase;
`

const RenewalValue = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => darken(0.08, theme.colors.success)};
  font-weight: bold;
  padding-top: .6rem;
`
const PixValue = styled.p`
  font-size:1.5rem;
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  padding-top: .6rem;
`

const RenewalIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: url(${CHECK_SUCCESS}) no-repeat ${({ theme }) => theme.colors.primary};
  background-position: 3px 3.5px;
`

const RenewalMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.85rem;
  opacity: 0.9;
`

const BlackDaysImage = styled.div`
  img {
    right: 6.2rem;
    position: absolute;
    height: 8rem;
    z-index: 10;
    bottom: 0.5rem;
    transform: rotate(20deg);
    ${media.lessThan('md')`
    right: 4.2rem;
    position: absolute;
    height: 5rem;
    z-index: 10;
    bottom: 5.5rem;
    transform: rotate(20deg);
    `}
  };
`
