import { gql } from '@apollo/client'

export const PLANS = gql`
  query EssayPlansCreditsByAccessTimeInMonths {
    essayPlansCreditsByAccessTimeInMonths {
      id
      accessTimeInMonths
      monthlyCredits
      totalValue
      numberOfInstallments
      valueOfInstallment
      expiresIn
    }
  }
`
