import React, { useState } from 'react'
import styled from 'styled-components'
import { ResumePay } from 'ui/resumebar/resume-pay'
import { ResumeTail } from 'ui/resumebar/resume-tail'
import { ResumeText } from 'ui/resumebar/resume-text'
import Expand from 'react-expand-animated'

export const ResumeBarMobile = () => {
  const [open, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!open)
  }

  return (
    <Wrapper>
      <Content1 onClick={toggle}>
        <Header>Resumo da Compra</Header>
        {open ? <Text>˅</Text> : <Text>˄</Text>}
      </Content1>
      <Expand
        open={open}
        duration='300'
      >
        <Line />
        <ResumeText />
        <Line />
        <ResumePay />
      </Expand>
      <Content>
        <ResumeTail />
      </Content>
    </Wrapper>
  )
}

const Line = styled.hr`
  width: 100%;
`
const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding-right: 2rem;
  font-family: Arial, Helvetica, sans-serif;
`

const Wrapper = styled.aside`
  position: stick;
  top: 0;
  font-size: 11px;
  z-index: 0;
  padding: 1rem;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 800;
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  padding: 1rem;
`

const Content1 = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
`
