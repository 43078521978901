import React from 'react'
import styled from 'styled-components'
import PlanStep from 'steps/plan'
import EssayCreditStep from 'steps/essay-credits'
import { ResumeBar } from 'ui'
import { media, PAGES } from 'resources'
import { useFormData } from 'contexts'
import { useWindowWidth } from '@react-hook/window-size/throttled'

import { usePage } from 'contexts/page'
import './sidebar.css'
import { ResumeBarMobile } from 'ui/resumebar-mobile/resumebar-mobile'

export const Sidebar = () => {
  const { formData, typeOfProduct } = useFormData()
  const width = useWindowWidth()
  const { page } = usePage()

  const data = formData.selectedPlan

  return (
    <Wrapper>
      {page !== PAGES.confirm &&
        <Content>
          <Header>
            {typeOfProduct === 'ESSAY_CREDITS'
              ? <EssayCreditStep />
              : <PlanStep />}
          </Header>
        </Content>}
      {width >= 900 &&
        <>
          {
            data && typeOfProduct !== 'ESSAY_CREDITS'
              ? (
                <Content1>
                  <ResumeBar />
                </Content1>
              ) : null
          }
        </>}
      {width < 900 &&
        <>
          {
            formData.selectedPlan
              ? (
                <Container2>
                  <ResumeBarMobile />
                </Container2>
              ) : null
          }
        </>}
    </Wrapper>
  )
}

const Container2 = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top-left-radius: ${({ theme }) => theme.radius.lg};
  border-top-right-radius: ${({ theme }) => theme.radius.lg};
  background: ${({ theme }) => `linear-gradient(60deg, ${theme.colors.lightBlue}, ${theme.colors.primary})`};
  color: ${({ theme }) => theme.colors.background};
  z-index: 1;

`

const Wrapper = styled.aside`
  position: relative;
  top: 0;
  padding: 1rem;

  ${media.lessThan('md')`
  display: flex;
  justify-content: center;
  `}
`

const Content = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.lightPurple};
  border-radius: ${({ theme }) => theme.radius.lg};
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  /* ${media.lessThan('xl')`
    padding-left: 7rem;
  `}

  ${media.lessThan('lg')`
    padding-left: 5rem;
  `} */

  ${media.greaterThan('xl')`
  width: 510px;
  `}

  ${media.lessThan('md')`
  width: 545px;
  `}

  ${media.lessThan('sm')`
    width: 100%;
  `}
`
const Content1 = styled.div`
  position: relative;
  margin-top: 4rem;
  border: 1px solid ${({ theme }) => theme.colors.lightPurple};
  border-radius: ${({ theme }) => theme.radius.lg};
  background: ${({ theme }) => `linear-gradient(60deg, ${theme.colors.lightBlue}, ${theme.colors.primary})`};
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10rem;

  /* ${media.lessThan('xl')`
    padding-left: 7rem;
  `}

  ${media.lessThan('lg')`
    padding-left: 5rem;
  `} */

  ${media.greaterThan('xl')`
  width: 510px;
  `}

`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem;
`
