import styled from 'styled-components'
import { media } from 'resources'

export const Wrapper = styled.section`
  width: 460px;
  ${media.lessThan('sm')`
    width: 100%;
  `}
`

export const Form = styled.form`
  width: 100%;
  max-width: 46rem;

  ${media.lessThan('md')`
    max-width: 100%;
  `}
`

export const SendArea = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('sm')`
    flex-direction: column;
    >*:nth-child(2){
      margin: 1rem;
    }
  `}
`

export const FormItem = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  grid-auto-flow: column;

  ${media.lessThan('sm')`
    display: block;
  `}
`
