
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from '../steps/home/home'
import EssayCreditStep from '../steps/essay-credits/'
import PlanStep from '../steps/plan'
import PaymentStep from '../steps/payment/payment'
import ConfirmStep from '../steps/confirm/confirm'
import TabStep from '../steps/tabs/tabs'
import Private from './Private'
import { ConfirmCard } from 'steps/confirm/components/card/confirm-card'
import { ConfirmBoleto } from 'steps/confirm/components/boleto/confirm-boleto'
import { ConfirmPix } from 'steps/confirm/components/pix/confirm-pix'
import { ConfirmedPix } from 'steps/confirm/components/pix/confirmed-pix'

function RoutesApp () {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/essay' element={<EssayCreditStep />} />
      <Route path='/plan' element={<PlanStep />} />
      <Route path='/personal' element={<TabStep />} />
      <Route path='/creditos-redacao' element={<TabStep />} />
      <Route path='/payment' element={<Private functionalityName='PaymentStep'> <PaymentStep /> </Private>} />
      <Route path='/confirm' element={<Private functionalityName='ConfirmStep'> <ConfirmStep /> </Private>} />
      <Route path='/pagamento-aprovado' element={<Private functionalityName='ConfirmCard'> <ConfirmCard /> </Private>} />
      <Route path='/boleto-emitido' element={<Private functionalityName='ConfirmBoleto'> <ConfirmBoleto /> </Private>} />
      <Route path='/pix-emitido' element={<Private functionalityName='ConfirmPix'> <ConfirmPix /> </Private>} />
      <Route path='/pagamento-aprovado-pix' element={<Private functionalityName='ConfirmedPix'> <ConfirmedPix /> </Private>} />
    </Routes>
  )
}

export default RoutesApp
