import validarCpf from 'validar-cpf'

export const isValidCPF = number => {
  if (number === '') {
    return true
  } else {
    const rawCPF = cpf => cpf.match(/\d/g).join('')
    const CPF = rawCPF(number)
    return validarCpf(CPF)
  }
}
