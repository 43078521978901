import styled from 'styled-components'
import styledBy from 'styled-by'

import { media } from 'resources'

export const Result = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.third};
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.radius.lg};
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${media.greaterThan('sm')`
    padding: ${({ theme }) => theme.spacing.md};
  `}
`

export const Header = styled.div`
  padding: 0 0 4rem;
`

export const Title = styled.h3`
  font-size: clamp(1.6rem, 5vw, 2.2rem);
  font-weight: 700;
  display: flex;
  align-items: center;

  ${styledBy('status', {
    authorized: ({ theme }) => `color: ${theme.colors.success}`,
    processing: ({ theme }) => `color: ${theme.colors.primary}`,
  })};

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`

export const Information = styled.p`
  font-size: 1.5rem;
  line-height: 1.5;
  padding: ${({ theme }) => theme.spacing.lg} 0 0;
`

export const Details = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const DetailsItem = styled.li`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('sm')`
    font-size: 1rem;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.third};
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    padding-bottom: ${({ theme }) => theme.spacing.sm};
  }

  > *:first-child {
    font-weight: 700;
  }
`
