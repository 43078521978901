import { gql } from '@apollo/client'

export const PLANS = gql`
  query Plans{
    plans {
      id
      name
      order
      bestOption
      icon
      accessTimeInMonths
      totalValue
      numberOfInstallments
      valueOfInstallment
      renewalDiscount
      valueWithRenewalDiscount
      valueWithCoupon
      valueOfInstallmentWithCoupon
      valueOfInstallmentWithRenewalDiscount
      hasEssay
      monthlyCredits
      valueWithPixDiscount
    }
  }
`

export const VALIDATE_COUPON = gql`
  query ValidateCoupon($input: ValidateCouponInput!) {
    validateCoupon(input: $input){
      coupon
      plan {
        valueWithCoupon
        valueOfInstallmentWithCoupon
        valueWithPixDiscount
      }
    }
  }
`
