import React from 'react'
import t from 'prop-types'
import { media } from 'resources'
import styled from 'styled-components'
import { Logo, SecureBadge, Button } from 'ui'
import errorIcon from './error-icon.svg'

export const UnexpectedError = ({
  message = 'Ocorreu um erro inesperado, por favor tente novamente em alguns minutos',
  code,
}) => {
  const handleClick = () => {
    window.location.assign('/')
  }

  return (
    <Wrapper>
      <Headline>
        <Logo />
        <SecureBadge>Ambiente 100% Seguro</SecureBadge>
      </Headline>
      <Content>
        <Icon src={errorIcon} />
        <Text>{message}</Text>
        <Code>Codigo: {code}</Code>
        <Button onClick={handleClick}>Recarregar página</Button>
      </Content>
    </Wrapper>
  )
}

UnexpectedError.propTypes = {
  message: t.string,
  code: t.string,
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;

`

const Wrapper = styled.div`

`
const Code = styled.div`
  padding: 1rem 1rem 3rem 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: rgb(160,160,160) ;
`

const Icon = styled.img`
  margin-bottom: 3.5rem;
`

const Text = styled.p`
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: -0.05em;
  text-align: center;
`
const Headline = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;
  padding: 2rem 3rem;
  background: ${({ theme }) => `linear-gradient(60deg, ${theme.colors.lightBlue}, ${theme.colors.primary})`};
  z-index: 1;

  ${media.lessThan('sm')`
    padding: 2rem 2rem 1.4rem;
  `}
`
