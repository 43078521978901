import React from 'react'

import { Navigate } from 'react-router-dom'
import { useFormData } from 'contexts'

export default function Private ({ children, functionalityName }) {
  const { formData } = useFormData()

  if (['ConfirmCard', 'ConfirmBoleto', 'ConfirmPix', 'ConfirmedPix'].includes(functionalityName)) {
    if (!formData.paymentResult) {
      return <Navigate to='/' />
    }
  }

  if (functionalityName === 'PaymentStep') {
    if (!formData.personalData) {
      return <Navigate to='/' />
    }
  }
  return children
}
