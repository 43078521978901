import React, { forwardRef } from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'

import { CheckField } from 'ui'

export const Terms = forwardRef(({ name, error }, ref) => {
  return (
    <Wrapper error={error}>
      <CheckField
        label='Li e aceito os '
        ref={ref}
        name={name}
      >
        <TermsLink
          href='https://professorferretto.com.br/politica-de-privacidade#termos-de-uso'
          target='__blank'
          rel='noopener'
        >
          termos de uso do Professor Ferretto
        </TermsLink>
      </CheckField>
    </Wrapper>
  )
})

Terms.propTypes = {
  name: t.string,
  error: t.object,
}

const Wrapper = styled.div`
  position: relative;
  margin: 2rem 0;

  &:before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: -1rem;
    bottom: 0;
    right: -1rem;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: border .2s ease;
  }

  ${({ error }) => error && css`
    &:before {
      border-color: ${({ theme }) => theme.colors.error};
    }
  `}
`

const TermsLink = styled.a`
  font-size: inherit;
  line-height: 1.3;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  transition: color .25s ease;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.third};
  padding-bottom: .2rem;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`
