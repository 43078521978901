import React from 'react'
import styled from 'styled-components'
import ShowMoreText from 'react-show-more-text'

import { useFormData } from 'contexts'

export const ResumeText = () => {
  const { formData } = useFormData()
  const data = formData.selectedPlan

  return (
    <>
      <Text>Plano</Text>
      <StepWrapper>{data.name} de acesso</StepWrapper><br /><br />
      <StepWrapper>Todas as matérias</StepWrapper>
      <Text>✓ Matemática</Text><br />
      <Text>✓ Ciências da Natureza</Text><br />
      <Text>✓ Ciências Humanas</Text><br />
      <Text>✓ Linguagens</Text><br />
      <Text>✓ Curso de Redação</Text><br />
      <Text>✓ Correção de redação <strong>{data.monthlyCredits} créditos mensais</strong></Text><br /><br />
      <StepWrapper>Planos de estudo completos para</StepWrapper>
      <Text>✓ Enem e Vestibular</Text><br />
      <ShowMoreText
        lines={1}
        more=<Strong>˅ <u>Ver mais</u></Strong>
        less=<Strong>^ <u>Ver menos</u></Strong>
        anchorClass=''
        truncatedEndingComponent=''
      >
        <Text>✓ Revisão para a Prova do ENEM</Text><br />
        <Text>✓ Curso por Questões</Text><br />
        <Text>✓ Mais de 16.000 questões resolvidas em vídeo</Text><br />
        <Text>✓ Simulados e Gerador de Simulados</Text><br />
        <Text>✓ Curso de discursivas</Text><br />
        <Text>✓ Estatísticas individuais</Text><br />
        <Text>✓ Comunidade de alunos com monitores</Text><br />
        <Text>✓ Resumos gráficos</Text><br /><br />

        <StepWrapper>Bônus</StepWrapper>
        <Text>✓ Método Avenger</Text><br />
        <Text>✓ Inglês</Text><br />
        <Text>✓ Espanhol</Text><br /><br />

      </ShowMoreText>
    </>
  )
}

const Strong = styled.div`
  cursor: pointer;
  font-weight: bold;
  border-color: black;
  border-width: 4rem;
`

const StepWrapper = styled.li`
  display: flex;
  align-items: center;
  font-weight: 800;
`
const Text = styled.a`
font-size: 14px;
`
