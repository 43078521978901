import React from 'react'
import styled from 'styled-components'

import logo from './logo.svg'

export const Logo = () => {
  return (
    <h1>
      <StyleLogo src={logo} alt='Professor Ferretto' />
    </h1>
  )
}

const StyleLogo = styled.img`width: 200px;`
