import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import t from 'prop-types'

import errorIcon from './error-icon.svg'

export const Alert = ({
  visible = false,
  message = 'Ocorreu um erro inesperado, por favor tente novamente em alguns minutos',
}) => (
  <Wrapper visible={visible}>
    <Icon src={errorIcon} />
    <Message>{message}</Message>
  </Wrapper>
)

export const useAlert = () => {
  const [alert, toggleAlert] = useState({
    message: '',
    show: false,
  })

  const showAlert = useCallback(message => {
    window.scrollTo(0, 0)
    toggleAlert({
      show: true,
      message,
    })
  }, [toggleAlert])

  const closeAlert = useCallback(() => toggleAlert({
    show: false,
  }), [toggleAlert])

  return [
    alert,
    showAlert,
    closeAlert,
  ]
}

Alert.propTypes = {
  message: t.string,
  visible: t.bool,
}

const Wrapper = styled.div`
  border: solid 1px red;
  display: ${p => p.visible ? 'flex' : 'none'};
  opacity: ${p => p.visible ? 1 : 0};
  background: #ff3f2f24;
  border: solid 2px ${p => p.theme.colors.error};
  border-radius: 4px;
  padding:1.25rem;
  margin-bottom: 1.5rem;
  align-items: center;
`

const Message = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: ${p => p.theme.colors.error};
`

const Icon = styled.img`
  width: 2.4rem;
  margin-right: 2rem;
`
