import React from 'react'
import styled from 'styled-components'

import { ResumeText } from './resume-text'
import { ResumePay } from './resume-pay'
import { ResumeTail } from './resume-tail'

export const ResumeBar = () => {
  return (
    <Wrapper>
      <Content1>
        <Header>Resumo da Compra</Header>
        <ResumeText />
      </Content1>
      <Line />
      <ResumePay />
      <Content>
        <ResumeTail />
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  position: stick;
  top: 0;
  font-size: 16px;
`
const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  font-size: 25px;
  font-weight: 800;
`
const Content = styled.div`
  padding: 2rem 9.5rem 6rem 9.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`
const Content1 = styled.div`
   padding: 5rem 6rem 2rem 9.5rem;
`
const Line = styled.hr`
`
