import React from 'react'
import { GlobalStyle } from 'resources'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'

import Providers from './providers'
import App from './app'

const { NODE_ENV } = process.env
const isDevelopment = NODE_ENV !== 'production'

ReactGA.initialize('UA-69485120-1', {
  debug: isDevelopment,
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
})

const Root = () => {
  return (
    <Providers>
      <GlobalStyle />
      <App />
    </Providers>
  )
}

export default Root
