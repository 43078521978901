import React from 'react'
import styled from 'styled-components'
import {
  media,
  cpfMask,
  errorMessages,
  isValidCPF,
} from 'resources'

import { SelectField, Button, Terms, SelectedPlanInfos, Alert, Spinner, InputField } from 'ui'
import { useBoleto } from './use-boleto'
import { useFormData } from 'contexts'
import { T } from 'ramda'

export const PaymentBoleto = () => {
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    alert,
    loading,
    totalValue,
  } = useBoleto()

  const readonly = false
  const { formData, typeOfProduct } = useFormData()

  const dados = formData.personalData
  const { selectedPlan } = formData
  const haveTransaction = typeOfProduct === 'ESSAY_CREDITS' ? false : formData.haveRecentTransaction

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Alert message={alert.message} visible={alert.show} />
      <FormItem>
        <InputField
          label='CPF'
          name='cpf'
          id='cpf'
          placeholder='000.000.000-00'
          inputMode='numeric'
          defaultValue={dados ? dados.cpf ? cpfMask(dados.cpf) : '' : ''}
          readOnly={readonly}
          error={errors.cpf}
          onChange={e => { e.target.value = cpfMask(e.target.value) }}
          inputRef={register({
            required: errorMessages.required,
            minLength: {
              value: 14,
              message: errorMessages.minLength,
            },
            validate: {
              cpfIsValid: readonly ? T : value => isValidCPF(value) || errorMessages.invalidCPF,
            },
          })}
        />
      </FormItem>
      <SelectField
        id='installments'
        name='installments'
        label='Número de parcelas *'
        options={selectedPlan ? [{ label: `Parcela única de ${totalValue.formatted}`, value: '1' }] : [{ label: 'Selecione um Plano', value: '1' }]}
        inputRef={register({ required: errorMessages.selectOption })}
      />

      <SelectedPlanInfos />

      <SendArea>
        <Terms
          name='terms'
          ref={register({
            required: true,
          })}
          error={errors.terms}
        />
        {!loading && <Button disabled={loading || haveTransaction || !selectedPlan}>Gerar Boleto</Button>}
        {loading && <Spinner />}
      </SendArea>
    </Form>
  )
}

const Form = styled.form`
  max-width: 61rem;
`

export const SendArea = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('sm')`
    flex-direction: column;
    >*:nth-child(2){
      margin: 1rem 0;
    }
  `}
`

export const FormItem = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  grid-auto-flow: column;

  ${media.lessThan('sm')`
    display: block;
  `}
`
