import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import { Step } from 'ui'
import { PAGES, translatePageName } from 'resources'
import { usePage } from 'contexts/page'
import ConfirmStep from 'steps/confirm'
import { Navigate, useLocation } from 'react-router-dom'

const Home = () => {
  const { page } = usePage()
  const { search } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    ReactGA.pageview(`/checkout/${translatePageName(page)}`)
  }, [page])

  return (
    <>
      {page === PAGES.personalData && (
        <Step visible={page === PAGES.personalData}>
          <Navigate to={`/personal${search}`} replace />
        </Step>)}

      {page === PAGES.payment && (
        <Step visible={page === PAGES.payment}>
          <Navigate to='/payment' replace />
        </Step>)}

      <Step visible={page === PAGES.confirm}>
        <ConfirmStep />
      </Step>
    </>
  )
}

export default Home
