import React, { useState, createContext, useContext } from 'react'
import { UnexpectedError } from 'ui'
import t from 'prop-types'

const ErrorHandlerContext = createContext({ setIsError: () => null })

export const ErrorHandlerProvider = ({ children }) => {
  const [isError, setIsError] = useState({ Error: false, code: undefined })
  return (
    <ErrorHandlerContext.Provider value={{ setIsError }}>

      {
        isError.Error ? <UnexpectedError code={isError.code} /> : children
      }
    </ErrorHandlerContext.Provider>
  )
}

ErrorHandlerProvider.propTypes = {
  children: t.node,
}

export function useErrorHandler () {
  const context = useContext(ErrorHandlerContext)
  if (!context) throw new Error('useErrorHandler must be used within a ErrorHandlerProvider')
  return context
}
