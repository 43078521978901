import React, { useEffect } from 'react'
import { usePage, useFormData } from 'contexts'
import { dispatchResetForm } from 'resources'
import { PaymentTypes } from 'resources/types/payment'
import { isEmpty } from 'ramda'
import { Navigate } from 'react-router-dom'

const ConfirmStep = () => {
  const { page, setCompletedPages } = usePage()
  const { formData } = useFormData()

  const { paymentResult } = formData

  useEffect(() => {
    setCompletedPages(completedPages => ({
      ...completedPages,
      [page]: true,
    }))
  }, [page, setCompletedPages])

  useEffect(() => {
    if (paymentResult && !isEmpty(paymentResult.type)) {
      dispatchResetForm()
    }
  }, [paymentResult])

  if (paymentResult) {
    return (
      <>
        {paymentResult.type === PaymentTypes.CREDIT_CARD && (
          <Navigate to='/pagamento-aprovado' replace />)}
        {paymentResult.type === PaymentTypes.BOLETO && (
          <Navigate to='/boleto-emitido' replace />)}
        {paymentResult.type === PaymentTypes.PIX && (
          <Navigate to='/pix-emitido' replace />)}
        {paymentResult.type === PaymentTypes.COUPON && (
          <Navigate to='/pagamento-aprovado' replace />)}
      </>
    )
  }

  return null
}

export default ConfirmStep
