import React from 'react'
import { getWebPlatformRedirectUrl } from 'resources'
import styled from 'styled-components'
import { StepHeader, Button, Checkmark } from 'ui'

import { Result, Header, Title, Information, Details, DetailsItem } from '../../styles/shared'
import { useConfirmPix } from './use-confirm-pix'
import { PaymentStatus } from 'resources/types/payment'

export const ConfirmedPix = () => {
  const {
    totalValue,
    planName,
    accessTimeInMonths,
  } = useConfirmPix()

  const StatusText = {
    [PaymentStatus.APPROVED]: {
      name: 'authorized',
      title: 'Compra aprovada',
      information: 'Fique de olho no seu e-mail, em breve você receberá as informações de acesso a plataforma. Bons estudos!',
      icon: Checkmark,
    },
  }
  const status = StatusText[PaymentStatus.APPROVED]
  return (
    <Wrapper>
      <StepHeader
        title='Obrigado!'
        subTitle='Confira as informações da sua compra'
      />
      <Result>
        <Header>
          <Title status={status.name}>
            <status.icon active color='green' />
            <span>{status.title}</span>
          </Title>
          <Information>{status.information}</Information>
        </Header>

        <Details>
          <DetailsItem>
            <span>Plano escolhido</span>
            <span>{planName}</span>
          </DetailsItem>
          <DetailsItem>
            <span>Tempo de acesso</span>
            <span>{accessTimeInMonths}</span>
          </DetailsItem>
          <DetailsItem>
            <span>Valor pago</span>
            <span>{totalValue.formatted} à vista no PIX</span>
          </DetailsItem>
        </Details>

      </Result>
      <Button
        as='a'
        href={getWebPlatformRedirectUrl()}
      >
        Ir para a plataforma
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
