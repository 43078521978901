import { useFormData } from 'contexts'
import { getPlanValue } from 'resources'
import { useState } from 'react'

export const useConfirmBoleto = () => {
  const { formData } = useFormData()
  const [copied, setCopied] = useState(false)

  const copy = (boletoCode) => {
    navigator.clipboard.writeText(`${boletoCode}`)
    setCopied(true)
  }

  return {
    totalValue: getPlanValue(formData.selectedPlan),
    planName: formData.selectedPlan?.name,
    boletoUrl: formData?.paymentResult?.boletoUrl,
    boletoCode: formData?.paymentResult?.boletoBarCode,
    copy,
    copied,
  }
}
