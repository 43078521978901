import React from 'react'
import styled from 'styled-components'
import { PlanCard, StepHeader, UnexpectedError, Alert } from 'ui'

import { ContentLoader } from './content-loader'
import { usePlan, Status } from './use-plan'
import { useFormData } from 'contexts'

const {
  DATA,
  LOADING,
} = Status

const PlanStep = () => {
  const {
    handlePlanSelect,
    handleCouponChange,
    plans,
    status,
    validatingCoupon,
    unexpectedError,
    alert,
    isBlackFriday,
  } = usePlan()

  const { formData } = useFormData()

  if (unexpectedError) {
    return <UnexpectedError />
  }

  return (
    <Wrapper>
      {status === LOADING && <ContentLoader />}
      {status === DATA && (
        <>
          <StepHeader subTitle='Escolha seu plano:' />
          <Alert message={alert.message} visible={alert.show} />
          <Plans>
            {plans.map((plan) => (
              <PlanCard
                key={plan.id}
                id={plan.id}
                title={plan.name}
                duration={plan.accessTimeInMonths}
                value={plan.totalValue}
                recommended={plan.bestOption}
                icon={plan.icon}
                active={plan.id === formData?.selectedPlan?.id}
                onSelect={handlePlanSelect}
                installmentValue={plan.valueOfInstallment}
                numberOfInstallments={plan.numberOfInstallments}
                onCouponChange={handleCouponChange}
                couponValue={plan.couponName}
                valueWithCoupon={plan.valueWithCoupon}
                installmentValueWithCoupon={plan.valueOfInstallmentWithCoupon}
                applyingCoupon={formData?.selectedPlan && validatingCoupon}
                couponStatus={plan.couponStatus}
                renewalValue={plan.valueWithRenewalDiscount}
                renewalInstallmentValue={
                  plan.valueOfInstallmentWithRenewalDiscount
                }
                hasEssay={plan.hasEssay}
                isBlackFriday={isBlackFriday}
                valueWithPixDiscount={plan.valueWithPixDiscount}
              />
            ))}
          </Plans>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: -3rem;
`

const Plans = styled.div`
  /* margin-bottom: ${({ theme }) => theme.spacing.lg}; */
`

export default PlanStep
