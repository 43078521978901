import styled, { css } from 'styled-components'
import { lighten } from 'polished'

import CARD_VISA from './card-flag__visa.jpg'
import CARD_MASTERCARD from './card-flag__mastercard.jpg'
import CARD_DINERS from './card-flag__diners-club.jpg'
import CARD_AMEX from './card-flag__american-express.jpg'
import CARD_DISCOVER from './card-flag__discover.jpg'
import CARD_HIPERCARD from './card-flag__hipercard.jpg'
import CARD_JCB from './card-flag__jcb.jpg'
import CARD_AURA from './card-flag__aura.jpg'
import CARD_ELO from './card-flag__elo.jpg'

const cards = {
  visa: CARD_VISA,
  mastercard: CARD_MASTERCARD,
  'diners-club': CARD_DINERS,
  'american-express': CARD_AMEX,
  discover: CARD_DISCOVER,
  hipercard: CARD_HIPERCARD,
  jcb: CARD_JCB,
  maestro: CARD_AURA,
  elo: CARD_ELO,
}

export const FieldControl = styled.div`
  display: block;
  position: relative;
  padding-top: 2.2rem;
  padding-bottom: 2rem;

  & + button {
    margin-top: ${({ theme }) => theme.spacing.sm};
  }

  ${({ disabled }) => disabled && css`
    opacity: .5;
    cursor: not-allowed;
  `}
`

export const FieldLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.2rem;
`

export const FieldError = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.error};
  position: absolute;
  right: 0;
  bottom: .5rem;
`

export const Field = styled.input`
  appearance: none;
  font-size: 1.6rem;
  display: block;
  height: 5.5rem;
  padding: .5rem 2rem;
  width: 100%;
  transition: box-shadow .2s ease;
  outline: 0;
  border: 1px solid ${({ error, theme }) => error ? theme.colors.error : lighten(0.4, theme.colors.lightText)};
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  background-size: auto 40px;
  background-repeat: no-repeat;
  background-position: calc(100% - .5rem) center;

  &:focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &::placeholder {
    color: ${({ theme }) => lighten(0.8, theme.colors.text)};
    letter-spacing: 0;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &[type="password"] {
    font-family: caption;
    letter-spacing: .2rem;

    &::placeholder {
      color: ${({ theme }) => lighten(0.8, theme.colors.text)};
      letter-spacing: .2rem;
      text-security: disc;
      -moz-text-security: disc;
      -webkit-text-security: disc;
    }
  }

  ${({ cardFlag }) => css`
    background-image: url(${cards[cardFlag]});
  `}
`
