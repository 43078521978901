import React from 'react'
import { media, getFinalValue } from 'resources'
import styled, { css } from 'styled-components'
import { StepHeader, Button } from 'ui'

import { Result, Header, Information, Details, DetailsItem } from '../../styles/shared'
import { useConfirmPix } from './use-confirm-pix'
import { Navigate } from 'react-router-dom'
import { useFormData } from 'contexts'

export const ConfirmPix = () => {
  const { formData } = useFormData()
  const {
    // totalValue,
    planName,
    qrCode,
    qrCodeImage,
    copy,
    copied,
    data,
  } = useConfirmPix()

  if (data?.checkPixPayment) {
    return (
      <>
        <Navigate to='/pagamento-aprovado-pix' replace />
      </>
    )
  }
  return (
    <Wrapper>
      <StepHeader
        title='Você está quase lá...'
        subTitle='Confira as informações e efetue o pagamento'
      />
      <Result>
        <PixWrapper>
          <QRCode>
            <img src={`${qrCodeImage}`} alt='QR Code' />
          </QRCode>
          <PixInfo>
            <span><Highlight>1.</Highlight> Abra o app do seu banco e entre em PIX.</span>
            <span><Highlight>2.</Highlight> Leia o QR Code ou copie e cole o código.</span>
            <span><Highlight>3.</Highlight> Confira as informações e confirme o pagamento.</span>
            <ButtonWrapper>
              <Button
                onClick={() => copy(qrCode)}
              >
                {!copied ? 'Copiar código PIX' : 'Copiado!'}
              </Button>
            </ButtonWrapper>
          </PixInfo>
        </PixWrapper>
        <span>Prazo de 2 dias para realização do pagamento. Após, o pedido será cancelado. </span>
      </Result>
      <Result>
        <Header>
          <Information>
            O seu acesso ao conteúdo pago da plataforma será disponibilizado <Highlight>após o pagamento do PIX ser processado.</Highlight> <Highlight alert>Isso pode demorar até 1 dia útil</Highlight>. Infelizmente, não é possível liberar o acesso antes do processamento bancário.
          </Information>
        </Header>
        <Details>
          <DetailsItem>
            <span>Plano escolhido</span>
            <span>{planName}</span>
          </DetailsItem>
          <DetailsItem>
            <span>Valor do plano</span>
            <span>{getFinalValue(formData)} à vista no PIX</span>
          </DetailsItem>
        </Details>
      </Result>
      <SendArea />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`

const Highlight = styled.span`
  font-weight: 600;

  ${({ alert, theme }) => alert && css`
    font-weight: 700;
    color: ${theme.colors.error};
  `}
  ${({ info, theme }) => info && css`
    font-weight: 300;
    color: ${theme.colors.lightBlue};
  `}
`
const PixWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ButtonWrapper = styled.div`
  padding-top: 2rem;
  button {
    width: 100%;
  }
`

const QRCode = styled.div`
  img {
    max-height: 20rem;
    max-width: 100%;
  }`

const PixInfo = styled.div`
  padding-bottom: 3rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  line-height: 20px;
`
const SendArea = styled.div`
padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('sm')`
    flex-direction: column-reverse;
    >*:nth-child(2) {
      margin-bottom: 1rem;
    }
  `}
`
