export const colors = {
  primary: '#0049ff',
  secondary: '#1b3064',
  third: '#e0edff',

  lightBlue: '#1479FF',
  lightGreen: '#43DFAB',
  lightPurple: '#C2D3FF',

  orange: '#F39C12',

  border: '#ccc',

  success: '#00d992',
  warning: '#ede847',
  error: '#ff3f2f',

  background: '#fff',
  text: '#000',
  lightText: '#555',
}

export const radius = {
  sm: '1rem',
  md: '1.5rem',
  lg: '2rem',
}

export const spacing = {
  sm: '1rem',
  md: '3rem',
  lg: '4rem',
}

export const theme = {
  colors,
  radius,
  spacing,
}
