import React from 'react'
import {
  StepHeader,
  Button,
  InputField,
  Spinner,
  Alert,
  UnexpectedError,
  CheckField,
} from 'ui'
import { phoneMask, errorMessages } from 'resources'
import { usePersonalData } from './use-personal-data'
import { Wrapper, Form, SendArea, FormItem } from './components'

const PersonalDataStep = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    errors,
    watch,
    readonly,
    checkingUser,
    alert,
    unexpectedError,
    loading,
  } = usePersonalData({ onAfterSubmit: console.log })

  const avoidCopyAndPaste = (e) => e.preventDefault()

  if (unexpectedError) {
    return <UnexpectedError />
  }

  return (
    <Wrapper>
      <StepHeader subTitle='Preencha os campos do(a) aluno(a) que irá usar a plataforma' />
      <Alert message={alert.message} visible={alert.show} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem>
          <InputField
            label='Nome *'
            name='name'
            id='name'
            placeholder='Nome'
            readOnly={readonly}
            error={errors.name}
            inputRef={register({
              required: errorMessages.required,
              minLength: {
                value: 2,
                message: errorMessages.minLength,
              },
            })}
          />
          <InputField
            label='Sobrenome *'
            name='surname'
            placeholder='Sobrenome'
            error={errors.surname}
            readOnly={readonly}
            inputRef={register({
              required: errorMessages.required,
              minLength: {
                value: 2,
                message: errorMessages.minLength,
              },
            })}
          />
        </FormItem>
        <FormItem>
          <InputField
            type='email'
            label='E-mail *'
            name='email'
            readOnly={readonly}
            inputRef={register({ required: errorMessages.required })}
            placeholder='nome@dominio.com'
            error={errors.email}
          />
        </FormItem>
        {!readonly && (
          <FormItem>
            <InputField
              type='email'
              label='Confirmar e-mail *'
              name='confirmEmail'
              onPaste={avoidCopyAndPaste}
              inputRef={register({
                required: errorMessages.required,
                validate: {
                  isEqual: (value) =>
                    value === watch('email') || errorMessages.mismatchedEmail,
                },
              })}
              placeholder='nome@dominio.com'
              error={errors.confirmEmail}
            />
          </FormItem>
        )}
        <FormItem>
          <InputField
            type='phone'
            inputMode='numeric'
            label='Telefone *'
            name='phone'
            placeholder='(XX) X XXXX-XXXX'
            readOnly={readonly}
            error={errors.phone}
            onChange={(e) => {
              e.target.value = phoneMask(e.target.value)
            }}
            inputRef={register({
              required: errorMessages.required,
              minLength: {
                value: 14,
                message: errorMessages.minLength,
              },
            })}
          />
        </FormItem>
        <FormItem>
          <InputField
            type='password'
            label='Senha *'
            name='password'
            readOnly={readonly}
            inputRef={register({ required: errorMessages.required })}
            placeholder='**********'
            error={errors.password}
          />
        </FormItem>
        <FormItem>
          <InputField
            type='password'
            label='Confirmar Senha *'
            name='confirmPassword'
            readOnly={readonly}
            inputRef={register({
              required: errorMessages.required,
              validate: {
                isEqual: value => value === watch('password') || errorMessages.mismatchedPassoword,
              },
            })}
            placeholder='**********'
            error={errors.confirmPassword}
          />
        </FormItem>
        <SendArea>
          <CheckField
            ref={register({ required: false })}
            label='Aceito receber dicas de estudo, conteúdos exclusivos e promoções'
            checked
            name='saveLead'
          />
          <Button disabled={loading} readOnly={checkingUser} hasIcon>
            Próximo passo
          </Button>
          {checkingUser && <Spinner />}
        </SendArea>
      </Form>
    </Wrapper>
  )
}

export default PersonalDataStep
