import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client'

// import { apolloSentry } from './apollo-sentry'

const API_URL = process.env.REACT_APP_API_URL

export const client = new ApolloClient({
  link: ApolloLink.from([
    // apolloSentry,
    createHttpLink({
      uri: API_URL,
    }),
  ]),
  cache: new InMemoryCache(),
})
