import React from 'react'
import { PaymentTypes } from 'resources/types/payment'
import styled from 'styled-components'
import { media } from 'resources'
import { useFormData } from 'contexts'
import { Smalllogo } from 'ui'

export const ResumePay = () => {
  const { formData } = useFormData()

  return (
    <>
      {formData.paymentType != null &&
        <>
          <Content2>
            <Text>Forma de Pagamento</Text>
            {formData.paymentType === 'Cartão de Crédito' && <Smalllogo type={PaymentTypes.CREDIT_CARD} color='white' />}
            {formData.paymentType === 'Boleto Bancário' && <Smalllogo type={PaymentTypes.BOLETO} color='white' />}
            {formData.paymentType === 'PIX' && <Smalllogo type={PaymentTypes.PIX} color='white' />}
            <StepWrapper>{formData.paymentType}</StepWrapper>
          </Content2>
          <Line />
        </>}
    </>
  )
}

const StepWrapper = styled.li`
  display: flex;
  align-items: center;
  font-weight: 800;
`
const Text = styled.a`
font-size: 14px;
`

const Content2 = styled.div`
  padding: 2rem 7rem 2rem 9.5rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  ${media.lessThan('md')`
    padding: 1rem;
  `}
`

const Line = styled.hr`
`
