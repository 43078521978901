import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { StepHeader, TabButton, Alert } from 'ui'
import { media } from 'resources'
import { PaymentTypes } from 'resources/types/payment'

import { PaymentCard } from './components/card/payment-card'
import { PaymentBoleto } from './components/boleto/payment-boleto'
import { PaymentPix } from './components/pix/payment-pix'
import { mergeLeft } from 'ramda'
import { useWindowWidth } from '@react-hook/window-size/throttled'
import { CouponStatus } from 'resources/types/plans'

import { useFormData } from 'contexts'

const PaymentStep = () => {
  const [paymentType, setPaymentType] = useState(PaymentTypes.PIX)
  const { setFormData } = useFormData()
  const [showPixMessage, setShowPixMessage] = useState(true)
  const width = useWindowWidth()

  useEffect(() => {
    setFormData(mergeLeft({
      paymentType: 'PIX',
    }))
  }, [setFormData])

  const handleSelectCreditCard = () => {
    setShowPixMessage(true)
    setPaymentType(PaymentTypes.CREDIT_CARD)
    setFormData(mergeLeft({
      paymentType: 'Cartão de Crédito',
    }))
  }
  const handleSelectBoleto = () => {
    setShowPixMessage(true)
    setPaymentType(PaymentTypes.BOLETO)
    setFormData(mergeLeft({
      paymentType: 'Boleto Bancário',
    }))
  }
  const handleSelectPix = () => {
    setShowPixMessage(true)
    setPaymentType(PaymentTypes.PIX)
    setFormData(mergeLeft({
      paymentType: 'PIX',
    }))
  }

  const { formData, typeOfProduct } = useFormData()

  const disable = !!(formData?.selectedPlan?.couponStatus === CouponStatus.APPLIED && formData?.selectedPlan?.valueWithCoupon?.raw === 0)

  useEffect(() => {
    if (disable) {
      setPaymentType(PaymentTypes.PIX)
      setFormData(mergeLeft({
        paymentType: 'CUPOM',
      }))
    }
  }, [disable, setFormData])

  return (
    <Wrapper>
      <StepHeader
        title='Forma de pagamento'
        subTitle='Selecione a forma de pagamento'
      />
      <Alert message='Já existe um registro recente de compra aprovada para esse cadastro. Em caso de dúvida, entre em contato com o suporte.' visible={formData.haveRecentTransaction && typeOfProduct !== 'ESSAY_CREDITS'} />
      <SelectPayment>
        <TabButton
          handleClick={handleSelectPix}
          active={paymentType === PaymentTypes.PIX}
          title='PIX'
          type={PaymentTypes.PIX}
          disabled={disable}
        />
        <TabButton
          handleClick={handleSelectCreditCard}
          active={paymentType === PaymentTypes.CREDIT_CARD}
          title='Cartão de crédito'
          type={PaymentTypes.CREDIT_CARD}
          disabled={disable}
        />
        <TabButton
          handleClick={handleSelectBoleto}
          active={paymentType === PaymentTypes.BOLETO}
          title='Boleto bancário'
          type={PaymentTypes.BOLETO}
          disabled={disable}
        />
      </SelectPayment>

      <TabContent visible={paymentType === PaymentTypes.CREDIT_CARD}>
        <PaymentCard />
      </TabContent>

      <TabContent visible={paymentType === PaymentTypes.BOLETO}>
        <PaymentBoleto />
      </TabContent>

      <TabContent visible={paymentType === PaymentTypes.PIX}>
        <PaymentPix />
      </TabContent>

      {showPixMessage && formData?.selectedPlan?.valueWithPixDiscount && width > 900 && (
        <PixMessageWrapper>
          <ClosePixMessage onClick={() => setShowPixMessage(false)}>X</ClosePixMessage>
          <PixMessageTitle>Ganhe desconto no PIX</PixMessageTitle>
          {paymentType === PaymentTypes.PIX ? (<PixMessage>Parabéns! Você está obtendo desconto com pagamento via PIX</PixMessage>) : (<PixMessage>Faça o pagamento com PIX para obter desconto.</PixMessage>)}
        </PixMessageWrapper>
      )}
    </Wrapper>
  )
}

export const ClosePixMessage = styled.button`
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  position: absolute;
  margin-left: auto;
  display: block;
  padding: 0;
  appearance: none;
  border: none;
  font-size: 1.4rem;
  top: 8px;
  right: 9px;
`
export const PixMessageWrapper = styled.div`
  background: #f6f8ff;
  border: 2px solid ${({ theme }) => theme.colors.success};
  border-radius: 10px;
  width: 235px;
  padding: 13px;
  position: relative;
  ${media.lessThan('md')`
    width: 100%;
  `}
`

export const PixMessageTitle = styled.p`
  color: ${({ theme }) => theme.colors.success};
  font-weight: bold;
  font-size: 1.45rem;
  ${media.lessThan('md')`
    font-size: 1.25rem;
  `}

`

export const PixMessage = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  padding-top: 8px;
  ${media.lessThan('md')`
  font-size: 1.1rem;
  `}
`

const Wrapper = styled.section`
  width: 101%;
  align-self: flex-start;
`

const SelectPayment = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${media.greaterThan('sm')`
    > *:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.sm};
    }
  `}

  ${media.lessThan('sm')`
    flex-direction: column;

    > button {
      width: 100%;
      margin: 0;
    }

    > *:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.sm};
    }
  `}
`

const TabContent = styled.div`
  display: ${p => p.visible ? 'block' : 'none'};
  width: 100%;
`

export default PaymentStep
