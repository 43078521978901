import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useWindowWidth } from '@react-hook/window-size/throttled'

import { PAGES, media } from 'resources'
import { usePage } from 'contexts/page'

import { Container, Sidebar, MobileHeader } from 'ui'

import { BrowserRouter } from 'react-router-dom'
import RoutesApp from './routes'
import { StepBoleto } from 'ui/step-card/step-boleto'
import { StepPix } from 'ui/step-card/step-pix'
import { useFormData } from 'contexts'
import { ErrorHandlerProvider } from 'contexts/ErrorHandler'
import { PlanContextProvider } from 'steps/plan/use-plan'

function App () {
  const width = useWindowWidth()
  const { page } = usePage()
  const { formData } = useFormData()

  useEffect(function preventBackButton () {
    window.addEventListener('popstate', (e) => {
      window.location = '/'
    })
  }, [])

  return (
    <PlanContextProvider>
      <BrowserRouter>
        <ErrorHandlerProvider>
          <MobileHeader />
          <Container>
            {(width < 900) && <Sidebar />}
            <Wrapper>
              <RoutesApp />
            </Wrapper>
            {(width >= 900) && <Sidebar />}
          </Container>
          {width > 900 &&
            <Container2>
              {(page === PAGES.payment && formData.paymentType === 'Boleto Bancário') && <StepBoleto active={1} />}
              {(page === PAGES.confirm && formData.paymentType === 'Boleto Bancário') && <StepBoleto active={2} />}
              {(page === PAGES.payment && formData.paymentType === 'PIX') && <StepPix active={1} />}
              {(page === PAGES.confirm && formData.paymentType === 'PIX') && <StepPix active={2} />}
            </Container2>}
        </ErrorHandlerProvider>
      </BrowserRouter>
    </PlanContextProvider>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 1rem 10rem;
  height: 100%;
  align-items: top;
  justify-content: center;

  ${media.greaterThan('xl')`
    padding: 1rem 12rem;
  `}

  ${media.lessThan('md')`
    padding: 1rem 7rem;
    margin-bottom: 15rem;
  `}

  ${media.lessThan('sm')`
    padding: 1rem;
  `}
`
const Container2 = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;

`

export default App
