import React from 'react'
import styled from 'styled-components'
import { PaymentTypes } from 'resources/types/payment'
import t from 'prop-types'

const SVGMap = {
  [PaymentTypes.CREDIT_CARD]: (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.42 14.1'>
      <path d='M15.92,0H2.5C1.12,0,0,1.12,0,2.5V11.6c0,1.38,1.12,2.5,2.5,2.5H15.92c1.38,0,2.5-1.12,2.5-2.5V2.5c0-1.38-1.12-2.5-2.5-2.5ZM2.5,2H15.92c.27,0,.5,.23,.5,.5v1.43H2v-1.43c0-.27,.23-.5,.5-.5ZM15.92,12.1H2.5c-.27,0-.5-.23-.5-.5V5.93h14.42v5.67c0,.27-.23,.5-.5,.5ZM2.79,8.19h5.61v2H2.79v-2Z' fill='#0049ff' />
    </svg>
  ),
  [PaymentTypes.BOLETO]: (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.42 14.1'>
      <path d='M18.42,3.72h-2v-1.72h-1.72V0h2.72c.55,0,1,.45,1,1V3.72ZM2,2h1.72V0H1C.45,0,0,.45,0,1V3.72H2v-1.72Zm1.72,10.1h-1.72v-1.72H0v2.72c0,.55,.45,1,1,1H3.72v-2Zm14.7,1v-2.72h-2v1.72h-1.72v2h2.72c.55,0,1-.45,1-1ZM15.7,3.72h-2v5.03h2V3.72Zm-2.75,0h-2v6.66h2V3.72Zm-2.75,0h-2v5.44h2V3.72Zm-2.75,0h-2V7.72h2V3.72Zm-2.75,0H2.72v6.14h2V3.72Z' fill='#0049ff' />
    </svg>
  ),
  [PaymentTypes.PIX]: (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.86 16.86'>
      <path d='M13.15,12.9c-.66,0-1.29-.26-1.75-.72l-2.53-2.53c-.19-.18-.48-.18-.66,0l-2.54,2.54c-.46,.47-1.09,.73-1.75,.72h-.5l3.2,3.2c1,1,2.62,1,3.62,0l3.21-3.21h-.31ZM3.92,3.95c.66,0,1.29,.26,1.75,.72l2.54,2.54c.18,.18,.48,.18,.66,0l2.53-2.53c.46-.47,1.09-.73,1.75-.73h.3L10.24,.75c-1-1-2.62-1-3.62,0,0,0,0,0,0,0L3.42,3.95h.5Zm12.19,2.67l-1.94-1.94s-.09,.03-.14,.03h-.88c-.46,0-.9,.18-1.22,.51l-2.53,2.53c-.47,.47-1.24,.47-1.72,0l-2.54-2.54c-.33-.32-.77-.51-1.22-.51h-1.08s-.09,0-.13-.03l-1.95,1.95c-1,1-1,2.62,0,3.62l1.95,1.95s.09-.03,.13-.03h1.09c.46,0,.9-.18,1.22-.51l2.54-2.54c.46-.46,1.26-.46,1.72,0l2.53,2.53c.33,.32,.77,.51,1.22,.51h.88s.09,0,.14,.03l1.94-1.94c1-1,1-2.62,0-3.62,0,0,0,0,0,0' fill='#0049ff' />
    </svg>
  ),
}

export const Smalllogo = ({ type, color = 'blue' }) => {
  return (
    <StyleLogo color={color}>
      {SVGMap[type]}
    </StyleLogo>
  )
}

Smalllogo.propTypes = {
  type: t.number,
  color: t.string,
}

const StyleLogo = styled.svg`
  width: 20px;
  height: 30px;
  path {
    fill: ${({ color }) => color === 'blue' ? '#0049ff' : color}
  }
`
