import React, { createContext, useState, useContext } from 'react'
import t from 'prop-types'

const TokenContext = createContext()

export default function TokenProvider ({ children }) {
  const [logToken, setLogToken] = useState('')

  return (
    <TokenContext.Provider
      value={{
        logToken,
        setLogToken,
      }}
    >
      {children}
    </TokenContext.Provider>
  )
}

TokenProvider.propTypes = {
  children: t.node,
}

export function useToken () {
  const context = useContext(TokenContext)
  if (!context) throw new Error('useToken must be used within a TokenProvider')
  return context
}
