import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'

import { FieldControl, FieldLabel, FieldError } from 'ui'

import icSelect from './ic-select.svg'

export const SelectField = ({ id, label, name, error, options, inputRef, disabled, onChange }) => {
  return (
    <FieldControl disabled={disabled}>
      <FieldLabel htmlFor={id}>{label}</FieldLabel>
      <Select
        id={id}
        name={name}
        disabled={disabled}
        ref={inputRef}
        error={error}
        onChange={onChange}
      >
        {options.map(item => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </Select>
      {error && <FieldError>{error?.message}</FieldError>}
    </FieldControl>
  )
}

SelectField.propTypes = {
  id: t.string.isRequired,
  label: t.string.isRequired,
  name: t.string.isRequired,
  error: t.object,
  options: t.arrayOf(t.shape({
    label: t.string.isRequired,
    value: t.string.isRequired,
  })).isRequired,
  disabled: t.bool,
  inputRef: t.func,
  onChange: t.func,
}

const Select = styled.select`
  appearance: none;
  position: relative;
  display: block;
  font-size: 1.6rem;
  height: 5.5rem;
  padding: .5rem 2rem;
  width: 100%;
  outline: 0;
  transition: box-shadow .2s ease;
  background-color: ${({ theme }) => theme.colors.background};
  background-image: url(${icSelect});
  background-repeat: no-repeat;
  background-position: calc(100% - 1.5rem) center;
  border: 1px solid ${({ error, theme }) => error ? theme.colors.error : theme.colors.border};
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${({ theme }) => theme.colors.text};

  &:focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &::placeholder {
    color: ${({ theme }) => lighten(0.6, theme.colors.text)};
    letter-spacing: 0;
  }

  &:disabled {
    pointer-events: none;
  }

  ${({ disabled }) => disabled && css`
    box-shadow: 0 10px 10px #eee inset;
  `}
`
