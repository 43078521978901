import React from 'react'

import { StepHeader, Button, Checkmark } from 'ui'
import { PaymentStatus } from 'resources/types/payment'
import { useFormData } from 'contexts'

import { Result, Header, Title, Information, Details, DetailsItem } from '../../styles/shared'
import { ReactComponent as Timer } from '../../icons/ic-timer.svg'
import { getPlanValue, getWebPlatformRedirectUrl } from 'resources'
import styled from 'styled-components'
import { CouponStatus } from 'resources/types/plans'

const StatusText = {
  [PaymentStatus.PENDING]: {
    name: 'processing',
    title: 'Em Aprovação',
    information: 'Seu pedido está em aprovação. O processo pode levar até 48 horas para ser concluído, mas não se preocupe, você será informado assim que tivermos um retorno da operadora, ok?',
    icon: Timer,
  },
  [PaymentStatus.APPROVED]: {
    name: 'authorized',
    title: 'Compra aprovada',
    information: 'Parabéns! Você acabou de dar um passo importante na sua jornada rumo à aprovação. Faremos tudo ao nosso alcance para que você tenha o que precisa para alcançar seu objetivo. Bons estudos!',
    icon: Checkmark,
  },
}

export const ConfirmCard = () => {
  const { formData } = useFormData()

  const { selectedPlan, paymentResult } = formData
  const status = StatusText[paymentResult.status]

  return (
    <Wrapper>
      <StepHeader
        title='Obrigado!'
        subTitle='Confira as informações da sua compra'
      />
      <Result>
        <Header>
          <Title status={status.name}>
            <status.icon active color='green' />
            <span>{status.title}</span>
          </Title>
          <Information>{status.information}</Information>
        </Header>

        <Details>
          <DetailsItem>
            <span>Plano escolhido</span>
            <span>{selectedPlan.name}</span>
          </DetailsItem>
          <DetailsItem>
            <span>Tempo de acesso</span>
            <span>{selectedPlan.accessTimeInMonths}</span>
          </DetailsItem>
          <DetailsItem>
            <span>Valor pago</span>
            <span>{selectedPlan?.couponStatus === CouponStatus.APPLIED && selectedPlan?.valueWithCoupon?.raw === 0 ? selectedPlan?.valueWithCoupon.formatted : getPaidValue(selectedPlan, paymentResult.installments)}</span>
          </DetailsItem>
        </Details>

      </Result>
      <Button
        as='a'
        href={getWebPlatformRedirectUrl()}
      >
        Ir para a plataforma
      </Button>
    </Wrapper>
  )
}
const getPaidValue = (plan, installments) => {
  const totalValue = getPlanValue(plan)

  return `${totalValue.formatted} em ${installments}x no cartão`
}

const Wrapper = styled.section`
  width: 100%;
`
