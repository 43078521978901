import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import styledBy from 'styled-by'

import { colors, showCheckedIcon } from 'resources'

import icCheckmark from './ic-checkmark.svg'

export const Checkmark = ({ color = 'blue', active, variant }) => {
  return <Check color={color} active={active} variant={variant} />
}

Checkmark.propTypes = {
  color: t.string,
  active: t.bool,
  variant: t.string,
}

const greenCheck = css`
  border: 1px solid ${colors.background};
`

const greenCheckActive = css`
  border-color: ${colors.success};
  background-color: ${colors.success};
`

const defaultCheck = css`
  border: 1px solid ${colors.lightPurple};
`

const defaultCheckActive = css`
  border-color: ${colors.primary};
  background-color: ${colors.primary};
`

const Check = styled.div`
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: relative;
  font-size: 1rem;

  ${({ variant }) =>
    variant === 'blue' &&
    css`
      background-color: #0d67ff;
    `}

  ${styledBy('color', {
    blue: ({ active }) => (active ? defaultCheckActive : defaultCheck),
    green: ({ active }) => (active ? greenCheckActive : greenCheck),
  })}

  ${({ active, theme }) =>
    active &&
    css`
      opacity: 1;

      &:before {
        content: url(${icCheckmark});
        position: absolute;
        left: 0.55rem;
        top: 0.55rem;
        animation: ${showCheckedIcon} 0.3s ease forwards;
      }
    `}
`
