import { useFormData, usePage } from 'contexts'
import { useState } from 'react'
import { PAGES, createContextWithToken } from 'resources'
import { CHECK_PIX_PAYMENT } from './graphql'
import { useQuery } from '@apollo/client'

export const useConfirmPix = () => {
  const { formData } = useFormData()
  const [copied, setCopied] = useState(false)
  const [loading] = useState(true)
  const { setPage } = usePage()
  const variables = {
    id: formData.paymentResult?.id,
  }
  const { data, stopPolling } = useQuery(CHECK_PIX_PAYMENT, {
    variables: variables,
    pollInterval: 10000,
    fetchPolicy: 'no-cache',
    context: createContextWithToken(JSON.parse(sessionStorage.getItem('proftkn'))),
  })

  if (data?.checkPixPayment) {
    stopPolling()
  }

  const copy = (qrCode) => {
    navigator.clipboard.writeText(`${qrCode}`)
    setCopied(true)
  }

  const handleBack = e => {
    e.preventDefault()
    setCopied(false)
    setPage(PAGES.payment)
  }

  function totalValueFormatted () {
    if (formData) {
      const data = formData.selectedPlan
      if (!data.valueWithRenewalDiscount && !data.valueWithCoupon) {
        return data.totalValue
      }
      if (data.valueWithRenewalDiscount && !data.valueWithCoupon) {
        return data.valueWithRenewalDiscount
      }
      if (data.valueWithCoupon && !data.valueWithRenewalDiscount) {
        return data.valueWithCoupon
      }
      if (data.valueWithCoupon && data.valueWithRenewalDiscount) {
        return data.valueWithCoupon <= data.valueWithRenewalDiscount
          ? data.valueWithCoupon
          : data.valueWithRenewalDiscount
      }
    }
  }

  return {
    totalValue: totalValueFormatted(),
    planName: formData?.selectedPlan?.name,
    qrCode: formData?.paymentResult?.qrCode,
    qrCodeImage: formData?.paymentResult?.qrCodeImage,
    accessTimeInMonths: formData?.selectedPlan.accessTimeInMonths,
    copy,
    copied,
    handleBack,
    data,
    loading,
  }
}
