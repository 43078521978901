import React, { useState } from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'

import { Checkmark } from 'ui'
import { media, PAGES } from 'resources'

import { usePage } from 'contexts/page'
import { PixMessageWrapper, ClosePixMessage, PixMessageTitle, PixMessage } from '../../steps/payment/payment'
import { useWindowWidth } from '@react-hook/window-size/throttled'
import { useFormData } from 'contexts'

export const SidebarSteps = ({ inline }) => {
  const { page, completedPages } = usePage()
  const [showPixMessage, setShowPixMessage] = useState(true)
  const width = useWindowWidth()
  const { formData } = useFormData()

  return (
    <>
      {showPixMessage && formData?.selectedPlan?.valueWithPixDiscount && width < 900 && (
        <PixMessageWrapper>
          <ClosePixMessage onClick={() => setShowPixMessage(false)}>X</ClosePixMessage>
          <PixMessageTitle>Ganhe desconto no PIX</PixMessageTitle>
          <PixMessage>Realize pagamento no PIX para pagar menos.</PixMessage>
        </PixMessageWrapper>
      )}
      <Wrapper inline={inline}>

        <StepList inline={inline}>
          <Line />
          <StepWrapper
            active={completedPages.personalData && page !== PAGES.confirm}
          >
            <Checkmark
              color='green'
              variant='blue'
              active={completedPages.personalData}
            />
            <Step
              active={page === PAGES.personalData || completedPages.personalData}
            >
              Criar Conta
            </Step>
          </StepWrapper>
          <StepWrapper active={completedPages.payment && page !== PAGES.confirm}>
            <Checkmark
              color='green'
              variant='blue'
              active={completedPages.payment}
            />
            <Step active={page === PAGES.payment || completedPages.payment}>
              Pagamento
            </Step>
          </StepWrapper>
          <StepWrapper>
            <Checkmark
              color='green'
              variant='blue'
              active={completedPages.confirm}
            />
            <Step active={page === PAGES.confirm || completedPages.confirm}>
              Confirmação
            </Step>
          </StepWrapper>
        </StepList>
      </Wrapper>
    </>
  )
}

SidebarSteps.propTypes = {
  inline: t.bool,
}

const Line = styled.div`
  position: absolute;
  background-color: white;
  opacity: 0.4;
  height: 1px;
  width: 100%;
  margin-top: 9px;
`

const Wrapper = styled.div`
  width: 40%;
  ${media.lessThan('md')`
  width: 100%;
  padding: 2rem 10% 0;
`}
`

const StepList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;

  ${({ inline, theme }) =>
    inline &&
    css`
      display: flex;
      justify-content: space-between;

      > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
          margin-bottom: 0;
        }

        > span {
          font-size: 1rem;
          padding: 0;
          margin-top: 1rem;
        }

        &:first-of-type {
          transform: translateX(-50%);
        }

        &:last-of-type {
          transform: translateX(50%);
        }
      }
    `}
`

const StepWrapper = styled.li`
  display: flex;
  justify-content: space-between;

  ${media.greaterThan('md')`
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  `}
`

const Step = styled.span`
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  padding-left: 0;
  color: ${({ theme }) => theme.colors.background};
  opacity: 0.6;

  ${({ active }) =>
    active &&
    css`
      font-weight: 700;
      opacity: 1;
    `}
`
