import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Checkmark } from 'ui'
import { media } from 'resources'

export const EssayCreditCard = ({
  id,
  active,
  onSelect,
  title,
  numberOfInstallments,
  valueOfInstallment,
  value,
}) => {
  return (
    <Wrapper active={active} onClick={() => onSelect(id)}>
      <Checkmark active={active} />
      <Content>
        <Title>{title}</Title>

        <PlanValue>
          {numberOfInstallments > 1 ? `${numberOfInstallments}x de ${valueOfInstallment.formatted} ou ` : ''}{value.formatted}
        </PlanValue>

      </Content>
    </Wrapper>
  )
}

EssayCreditCard.propTypes = {
  id: t.string.isRequired,
  active: t.bool,
  title: t.string.isRequired,
  value: t.object,
  onSelect: t.func.isRequired,
  valueOfInstallment: t.object,
  numberOfInstallments: t.number,
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.lightPurple};
  border-radius: ${({ theme }) => theme.radius.lg};
  cursor: pointer;
  min-height: 108px;

  ${({ active, theme }) => active && css`
    border-color: ${theme.colors.primary};
    background-color: ${theme.colors.third};
    box-shadow: 0 0 2rem 0 ${rgba(theme.colors.primary, 0.1)};
  `}

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }

  ${media.lessThan('xxl')`
    padding: 1.6rem 2rem;
  `}

  ${media.greaterThan('md')`
    max-width: 54rem;
  `}

  ${media.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;
  `}
`
const Content = styled.div`
  width: 100%;
  padding-left: 1.5rem;

  ${media.lessThan('sm')`
    margin-top: ${({ theme }) => theme.spacing.sm};
  `}
`
const Title = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`
const PlanValue = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.lightText};

  ${({ invalid }) => invalid && css`
    text-decoration: line-through;
  `}
`
