import { gql } from '@apollo/client'

export const LOGINCHK = gql`
  mutation Login($input: LoginInput!){
    login(input: $input) {
      token    
      refreshToken
      __typename
      }
  }
`

export const ME = gql`
  {
    me {
      id
      name
      email
      surname
      cpf
      phone
      state
      city
      neighborhood
      street
      street_number
      zipcode
      complementary
    }
      haveRecentTransaction
  }
`
export const PLANS = gql`
  query Plans{
    plans {
      id
      name
      order
      bestOption
      icon
      accessTimeInMonths
      totalValue
      numberOfInstallments
      valueOfInstallment
      renewalDiscount
      valueWithRenewalDiscount
      valueWithCoupon
      valueOfInstallmentWithCoupon
      valueOfInstallmentWithRenewalDiscount
      hasEssay
      monthlyCredits
    }
  }
`
