/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import styled, { css } from 'styled-components'
import { hasUseRenewDiscount, getFinalValue } from 'resources'
import { useFormData } from 'contexts'
import { CouponStatus } from 'resources/types/plans'
import { isNil } from 'ramda'

export const ResumeTail = () => {
  const { formData } = useFormData()
  const { selectedPlan, selectedInstallment, paymentType } = formData

  const hasDiscount = checkIfHasDiscount(selectedPlan)
  const discountValue = getDiscountValue(selectedPlan)
  const installValue = getInstallValue(selectedPlan)

  return (
    <>
      {!formData.paymentType &&
        <div>
          <Text>Parcelado em </Text>

          <PlanValue invalid={hasDiscount}>
            {selectedPlan.numberOfInstallments}x de {selectedPlan.valueOfInstallment.formatted}
          </PlanValue>

          {hasDiscount &&
            <StepWrapper>
              {selectedPlan.numberOfInstallments}x de {installValue.formatted}
            </StepWrapper>}

          <Text>sem juros</Text>
        </div>}
      {formData.paymentType === 'Cartão de Crédito' &&
        <div>
          <Text>Parcelado em </Text>

          <PlanValue invalid={hasDiscount}>
            {selectedInstallment ? selectedInstallment.installment : selectedPlan.numberOfInstallments}
            x de {selectedInstallment ? selectedInstallment.originalValue : selectedPlan.valueOfInstallment.formatted}
          </PlanValue>

          {hasDiscount &&
            <StepWrapper>
              {selectedInstallment ? selectedInstallment.installment : selectedPlan.numberOfInstallments}
              x de {selectedInstallment ? selectedInstallment.value : installValue.formatted}
            </StepWrapper>}

          <Text>sem juros</Text>
        </div>}
      {formData.paymentType === 'Boleto Bancário' || formData.paymentType === 'PIX'
        ? <div>
          <StepWrapper>Total</StepWrapper>
          <PlanTotal invalid={hasDiscount}>{hasDiscount ? selectedPlan.totalValue.formatted : `${getFinalValue(formData)} à vista`} </PlanTotal>
          {hasDiscount &&
            <Text>{getFinalValue(formData)} à vista</Text>}
          {selectedPlan.valueWithPixDiscount && formData.paymentType === 'Boleto Bancário' && (
            <StepWrapper>
              <Text>
                ou {selectedPlan.valueWithPixDiscount.formatted} no PIX
              </Text>
            </StepWrapper>
          )}
        </div>
        : <div>
          <StepWrapper>Total</StepWrapper>
          <PlanTotal invalid={hasDiscount}>{hasDiscount ? selectedPlan.totalValue.formatted : getFinalValue(formData)}</PlanTotal>
          {hasDiscount &&
            <Text>{discountValue.formatted}</Text>}
          {selectedPlan.valueWithPixDiscount && paymentType !== 'CUPOM' && (
            <StepWrapper>
              <Text>
                ou {selectedPlan.valueWithPixDiscount.formatted} no PIX
              </Text>
            </StepWrapper>
          )}
        </div>}
    </>
  )
}

const checkIfHasDiscount = plan => plan.couponStatus === CouponStatus.APPLIED || !isNil(plan.valueWithRenewalDiscount)

const getDiscountValue = plan => {
  const hasCoupon = plan.couponStatus === CouponStatus.APPLIED

  if (hasUseRenewDiscount(plan, hasCoupon)) return plan.valueWithRenewalDiscount

  if (hasCoupon) return plan.valueWithCoupon
}

const getInstallValue = plan => {
  const hasCoupon = plan.couponStatus === CouponStatus.APPLIED

  if (hasUseRenewDiscount(plan, hasCoupon)) return plan.valueOfInstallmentWithRenewalDiscount

  if (hasCoupon) return plan.valueOfInstallmentWithCoupon
}

const StepWrapper = styled.li`
  display: flex;
  align-items: center;
  font-weight: 800;
`
const Text = styled.a`
font-size: 14px;
`

const PlanValue = styled.p`
  font-weight: 800;

  ${({ invalid }) => invalid && css`
    font-weight: 400;
    color: ${({ theme }) => theme.colors.lightText};
    text-decoration: line-through;
  `}
`

const PlanTotal = styled.p`

  ${({ invalid }) => invalid && css`
    color: ${({ theme }) => theme.colors.lightText};
    text-decoration: line-through;
  `}
`
