/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react'
import styled from 'styled-components'
import { InputField, SelectField, Button, Terms, SelectedPlanInfos, Spinner, Alert } from 'ui'

import {
  media,
  errorMessages,
  cardExpirationMask,
  cvvMask,
  cpfMask,
  isValidCPF,
} from 'resources'

import ACCEPTED_CARDS from './accepted-cards.jpg'
import { useCard } from './use-card'
import { useFormData } from 'contexts'
import { T } from 'ramda'

export const PaymentCard = () => {
  const {
    cardIsValid,
    dateIsValid,
    cardType,
    cardLength,
    cvvLength,
    register,
    handleSubmit,
    onSubmit,
    errors,
    cardFlag,
    installmentOptions,
    finishing,
    alert,
    handleChangeInstallments,
  } = useCard()

  const readonly = false
  const { formData, typeOfProduct } = useFormData()
  const dados = formData.personalData
  const { selectedPlan } = formData
  const haveTransaction = typeOfProduct === 'ESSAY_CREDITS' ? false : formData.haveRecentTransaction
  return (
    <Wrapper>
      <Alert
        message={alert?.message}
        visible={alert?.show}
      />
      <AcceptedCardsWrapper>
        <img src={ACCEPTED_CARDS} alt='Cartões aceitos' />
      </AcceptedCardsWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label='Número do Cartão *'
          name='cardNumber'
          placeholder='XXXX XXXX XXXX XXXX'
          error={errors.cardNumber}
          maxLength={cardLength}
          type='tel'
          cardFlag={cardFlag}
          onChange={cardType}
          inputRef={register({
            required: errorMessages.required,
            minLength: {
              value: 15,
              message: errorMessages.minLength,
            },
            validate: {
              isValidCc: value => cardIsValid(value) || errorMessages.invalidCard,
            },
          })}
        />
        <InputField
          label='Nome (igual ao impresso no cartão) *'
          name='cardHolderName'
          placeholder='Eliana M Silva'
          error={errors.cardHolderName}
          inputRef={register({
            required: errorMessages.required,
            minLength: {
              value: 2,
              message: errorMessages.minLength,
            },
          })}
        />
        <FieldSet>
          <InputField
            type='tel'
            label='Data de validade *'
            name='cardExpirationDate'
            placeholder='08/26'
            error={errors.cardExpirationDate}
            onChange={e => {
              e.target.value = cardExpirationMask(e.target.value)
            }}
            inputRef={register({
              required: errorMessages.required,
              minLength: {
                value: 3,
                message: errorMessages.minLength,
              },
              validate: {
                dateIsValid: value => dateIsValid(value) || errorMessages.invalidDate,
              },
            })}
          />
          <InputField
            type='tel'
            label='Código de segurança *'
            name='cvv'
            placeholder={cvvLength === 4 ? '1234' : '123'}
            maxLength={cvvLength}
            error={errors.cvv}
            onChange={e => { e.target.value = cvvMask(e.target.value) }}
            inputRef={register({
              required: errorMessages.required,
              minLength: {
                value: 3,
                message: errorMessages.minLength,
              },
            })}
          />
        </FieldSet>
        <SelectField
          id='installments'
          name='installments'
          label='Número de parcelas *'
          inputRef={register({ required: errorMessages.selectOption })}
          options={selectedPlan ? installmentOptions.sort((a, b) => a.value - b.value) : [{ label: 'Selecione um Plano', value: '' }]}
          error={errors.installments}
          onChange={typeOfProduct !== 'ESSAY_CREDITS' ? handleChangeInstallments : null}
        />
        <InputField
          label='CPF do Comprador'
          name='cpf'
          id='cpf'
          placeholder='000.000.000-00'
          inputMode='numeric'
          defaultValue={dados ? dados.cpf ? cpfMask(dados.cpf) : '' : ''}
          readOnly={readonly}
          error={errors.cpf}
          onChange={e => { e.target.value = cpfMask(e.target.value) }}
          inputRef={register({
            required: errorMessages.required,
            minLength: {
              value: 14,
              message: errorMessages.minLength,
            },
            validate: {
              cpfIsValid: readonly ? T : value => isValidCPF(value) || errorMessages.invalidCPF,
            },
          })}
        />
        <SelectedPlanInfos />
        <SendArea>
          <Terms
            name='terms'
            ref={register({
              required: true,
            })}
            error={errors.terms}
          />
          {!finishing && <Button disabled={finishing || haveTransaction || !selectedPlan} hasIcon>Pagar e concluir</Button>}
          {finishing && <Spinner />}
        </SendArea>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
`

const AcceptedCardsWrapper = styled.div`
  padding-bottom: 3rem;

  img {
    max-height: 3rem;
    max-width: 100%;
  }
`

const Form = styled.form`
  width: 100%;
  max-width: 61rem;

  ${media.lessThan('md')`
    max-width: 100%;
  `}
`

const FieldSet = styled.div`
  display: flex;
  justify-content: space-between;
  border: none;

  > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.sm};
    }
  }
`

export const SendArea = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('sm')`
    flex-direction: column;
    >*:nth-child(2){
      margin: 1rem;
    }
  `}
`
